// Import rollbar first to report errors on subsequent imports
import "../config/rollbar"
// Polyfill support for Internet Explorer
import "core-js/stable"
import "regenerator-runtime/runtime"
import "../config/classlist-shim"
import "../src/behaviors/popover"
import "../src/behaviors/scrollspy"

import "react-datepicker/dist/react-datepicker.css"

import downloadModal from "../src/loadingModal/downloadModal"
import uploadModal from "../src/loadingModal/uploadModal"
import pendoConfig from "../src/pendo/pendo"
import sessionLib from "../src/sessions/sessionManagement"

function importAll(r) {
  r.keys().forEach(r)
}

// require all JS files in app/components
importAll(require.context("components", true, /\.js$/))

// `require.context` bundles all files in the `src` directory via webpack and makes
// them available to react-rails.
// If you want to dynamically import a file so that it isn't included in the main JS
// bundle, include `.lazy` in the filename (for example, MyComponent.lazy.jsx)
const componentRequireContext = require.context("src", true, /^(?!.*\.lazy).*$/)
const ReactRailsUJS = require("react_ujs")

ReactRailsUJS.useContext(componentRequireContext)

window.HH = window.HH || {}
HH.DownloadModal = downloadModal
HH.UploadModal = uploadModal
window.SessionManagement = sessionLib.sessionManager
window.PendoConfig = pendoConfig
