import PropTypes from "prop-types"

import { redirectTo } from "~/browserHelpers"
import { health_home_care_suggestions_path } from "~/routes"
import types from "~/types"
import AutoSubmitForm from "~/views/shared/autoSubmitForm/AutoSubmitForm"

function CareSuggestionsBankSelector({ currentHealthHomeId, healthHomes }) {
  function handleSubmit({ healthHomeId }) {
    redirectTo(health_home_care_suggestions_path({ health_home_id: healthHomeId }))
  }

  return (
    <AutoSubmitForm initialValues={{ healthHomeId: currentHealthHomeId }} onSubmit={handleSubmit}>
      <AutoSubmitForm.SelectBox label="Select Health Home:" name="healthHomeId" options={healthHomes} />
    </AutoSubmitForm>
  )
}

CareSuggestionsBankSelector.propTypes = {
  currentHealthHomeId: PropTypes.number,
  healthHomes: PropTypes.arrayOf(types.selectOption).isRequired,
}

export default CareSuggestionsBankSelector
