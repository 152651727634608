import { FieldArray, useFormikContext } from "formik"

import { IconButton, LinkButton } from "~/design/buttons"
import Form from "~/design/forms"
import { CloseIcon } from "~/design/icons"

function NeedFormFields() {
  const rating = [1, 2, 3, 4, 5].map((number) => ({
    label: `${number}`,
    value: number,
  }))

  const YES_NO_OPTIONS = [
    { value: true, label: "Yes" },
    { value: false, label: "No" },
  ]

  const {
    values: { needs },
    setFieldValue,
  } = useFormikContext()

  const newNeedAttributes = {
    title: "",
    description: "",
    addressed: null,
    ratingOfMeetingNeed: null,
    context: "",
  }

  return (
    <FieldArray
      name="needs"
      render={({ push, remove }) => (
        <>
          {needs.map((need, needIndex) => {
            if (need._destroy) {
              return null
            }

            function removeNeed() {
              if (need.id) {
                setFieldValue(`needs[${needIndex}]._destroy`, true)
              } else {
                remove(needIndex)
              }
            }

            return (
              <div key={needIndex} className="border rounded p-3 mb-3">
                <div className="d-flex justify-content-end">
                  <IconButton Icon={CloseIcon} onClick={removeNeed} label={"Remove Underlying Need"} />
                </div>
                <Form.Group clearOnHide={false} name={`needs[${needIndex}].title`}>
                  <Form.Label>Underlying Need #{needIndex + 1}</Form.Label>
                  <Form.TextArea />
                </Form.Group>

                <Form.Group clearOnHide={false} name={`needs[${needIndex}].description`}>
                  <Form.Label>Underlying Need Description</Form.Label>
                  <Form.TextArea />
                </Form.Group>

                <Form.Group clearOnHide={false} name={`needs[${needIndex}].addressed`}>
                  <Form.Label>Does the current care plan address the Underlying Need?</Form.Label>
                  <Form.SelectBox options={YES_NO_OPTIONS} />
                </Form.Group>

                <Form.Group clearOnHide={false} name={`needs[${needIndex}].ratingOfMeetingNeed`}>
                  <Form.Label>How close are we to having this underlying need met?</Form.Label>
                  <Form.SelectBox options={rating} />
                </Form.Group>

                <Form.Group clearOnHide={false} name={`needs[${needIndex}].context`}>
                  <Form.Label>Please provide more context on the progress made on this underlying need:</Form.Label>
                  <Form.TextArea />
                </Form.Group>
              </div>
            )
          })}
          <div className="d-flex justify-content-end">
            <LinkButton onClick={() => push(newNeedAttributes)}>Add Underlying Need</LinkButton>
          </div>
        </>
      )}
    />
  )
}

export default NeedFormFields
