/* eslint-disable no-console */
export const browserWarning = (msg = "For the best experience on this site please update your browser") => {
  console.warn(msg)
}

export const updatePath = (newUrl, state = {}) => {
  if (!(window.history && window.history.pushState)) {
    browserWarning()
    return
  }

  if (!newUrl) {
    return
  }

  window.history.pushState(state, null, newUrl)
}

export const redirectTo = (url) => {
  if (!(window.location && window.location.replace)) {
    browserWarning()
    return
  }

  if (!url) {
    return
  }

  window.location.replace(url)
}

export default updatePath
