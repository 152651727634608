import { charts_path } from "~/routes"

export const createBig3HealthHomesPatientsDrillthruUrl = ({
  healthHomeId,
  careManagementAgencyId,
  date,
  statusValue,
  routeFunction = charts_path,
}) => {
  const filter = {
    active_in_health_home_as_of: {
      health_home_id: healthHomeId,
      date,
    },
    active_in_care_management_agency_as_of: {
      care_management_agency_id: careManagementAgencyId,
      date,
    },
    [statusValue]: date,
  }

  return routeFunction({ filter })
}

export const createBig3CareManagementAgencyPatientsDrillthruUrl = ({
  careManagementAgencyId,
  careManagerId,
  healthHomeIds,
  date,
  statusValue,
  adultChildServicesCodeId,
  routeFunction = charts_path,
}) => {
  const careManagerIdWithDefault = careManagerId || "blank"

  const filter = {
    care_management_agency_id: careManagementAgencyId,
    care_manager_id: careManagerIdWithDefault,
    [statusValue]: date,
  }

  if (adultChildServicesCodeId) {
    filter.segment_filter = {
      active_as_of: date,
      adult_child_services_code_id: adultChildServicesCodeId,
    }
  }

  if (healthHomeIds?.length > 0) {
    filter.active_in_health_home_as_of = {
      health_home_id: healthHomeIds,
      date,
    }
  }

  return routeFunction({ filter })
}
