const ELEMENT_DATA_SELECTOR = "[data-behavior=scrollspy-target]"

document.addEventListener("DOMContentLoaded", () => {
  // Since we use the body as the scrollspy container, there should only ever be a single scollspy
  // target on the screen at once.
  const scrollspyTarget = document.querySelector(ELEMENT_DATA_SELECTOR)

  if (scrollspyTarget) {
    const target = `#${scrollspyTarget.id}`
    const offset = parseInt(scrollspyTarget.dataset.offset, 10)

    $("body").scrollspy({ offset, target })
  }
})
