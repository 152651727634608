const DATA_SELECTOR = "[data-behavior=conditional-field-toggle]"

class ConditionalFieldToggle {
  static elementMap = new Map()

  static getOrCreateInstance(element) {
    return this.elementMap.get(element) || new this(element)
  }

  constructor(element) {
    this.element = element

    // Select2 works with jQuery, so we need to use jQuery here to listen
    // for "change" events and to retrieve the selected value
    this.$trigger = $(element.dataset.triggerSelector)

    // triggerValue can be an array or a single value
    this.triggerValue = element.dataset.triggerValue.startsWith("[")
      ? JSON.parse(element.dataset.triggerValue)
      : [element.dataset.triggerValue]

    this.clearOnHide = element.dataset.clearOnHide === "true"

    this.toggle()
    this.listen()

    // Cache elements to avoid re-initializing elements that are already initialized
    ConditionalFieldToggle.elementMap.set(element)
  }

  listen() {
    this.$trigger.on("change", this.toggle.bind(this))
  }

  toggle() {
    if (this.triggerHasValue()) {
      this.show()
    } else {
      this.hide()
    }
  }

  triggerHasValue() {
    return this.triggerValue.some((value) => {
      if (this.$trigger.is("input[type=checkbox]")) {
        return (
          (value === "checked" && this.$trigger.is(`:checked`)) ||
          (value === "unchecked" && !this.$trigger.is(`:checked`))
        )
      } else if (Array.isArray(this.$trigger.val())) {
        return this.$trigger.val().includes(String(value))
      } else {
        return this.$trigger.val() === String(value)
      }
    })
  }

  show() {
    this.element.style.display = null
  }

  hide() {
    if (this.clearOnHide) {
      this.clearValues()
    }
    this.element.style.display = "none"
  }

  clearValues() {
    for (const field of this.element.querySelectorAll("input")) {
      if (field.type === "checkbox") {
        field.checked = false
      } else if (field.type !== "submit") {
        field.value = ""
      }
    }

    for (const field of this.element.querySelectorAll("select")) {
      $(field).val(null).trigger("change")
    }
  }
}

// Initialize component when elements are ready.
// If we want these to work with AjaxForm (assessments), we need to use
// the content:updated event to determine when elements are ready.
$(document).on("content:updated", () => {
  for (const element of document.querySelectorAll(DATA_SELECTOR)) {
    ConditionalFieldToggle.getOrCreateInstance(element)
  }
})
