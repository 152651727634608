import { submitAsyncRequest } from "~/asyncHelpers"
import {
  api_v1_chart_high_fidelity_wrap_child_and_family_team_meetings_path,
  api_v1_high_fidelity_wrap_child_and_family_team_meeting_path,
} from "~/routes"

const highFidelityWrap = {
  childAndFamilyTeamMeetings: {
    patch: function (id, childAndFamilyTeamMeeting) {
      return submitAsyncRequest({
        url: api_v1_high_fidelity_wrap_child_and_family_team_meeting_path(id),
        type: "patch",
        data: { childAndFamilyTeamMeeting: childAndFamilyTeamMeeting },
        keyTransformOverrides: { _destroy: "_destroy" },
      })
    },
    post: function (chartId, childAndFamilyTeamMeeting) {
      return submitAsyncRequest({
        url: api_v1_chart_high_fidelity_wrap_child_and_family_team_meetings_path(chartId),
        type: "post",
        data: { childAndFamilyTeamMeeting: childAndFamilyTeamMeeting },
      })
    },
  },
}

export default highFidelityWrap
