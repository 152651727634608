import PropTypes from "prop-types"

const highFidelityWrapChildAndFamilyTeamMeeting = PropTypes.shape({
  id: PropTypes.number,
  meetingDate: PropTypes.string,
  familyVision: PropTypes.string,
  familyVisionRating: PropTypes.number,
  educationEmploymentStatusId: PropTypes.number,
  schoolMissedDays: PropTypes.string,
  workMissedDays: PropTypes.string,
  schoolWorkContext: PropTypes.string,
  needs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      title: PropTypes.string,
      description: PropTypes.string,
      addressed: PropTypes.bool,
      ratingOfMeetingNeed: PropTypes.number,
      context: PropTypes.string,
    })
  ).isRequired,
})

export default highFidelityWrapChildAndFamilyTeamMeeting
