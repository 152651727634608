import PropTypes from "prop-types"

import FormFields from "./FormFields"

import { redirectTo } from "~/browserHelpers"
import Form from "~/design/forms"
import { high_fidelity_wrap_child_and_family_team_meeting_path } from "~/routes"
import api from "~/services/api"
import types from "~/types"

function EditForm({
  educationEmploymentStatuses,
  childAndFamilyTeamMeeting,
  saveButtonDescription,
  completeButtonDescription,
}) {
  async function handleSubmit(values, { setErrors, setStatus }) {
    const { needs, ...childAndFamilyTeamMeeting } = values

    const childAndFamilyTeamMeetingParams = {
      ...childAndFamilyTeamMeeting,
      needsAttributes: needs,
    }

    try {
      await api.highFidelityWrap.childAndFamilyTeamMeetings.patch(
        childAndFamilyTeamMeeting.id,
        childAndFamilyTeamMeetingParams
      )
      setStatus("success")
      redirectTo(high_fidelity_wrap_child_and_family_team_meeting_path(childAndFamilyTeamMeeting.id))
    } catch ({ errors }) {
      setErrors(errors)
      window.scrollTo(0, 0)
    }
  }

  return (
    <Form vertical initialValues={childAndFamilyTeamMeeting} onSubmit={handleSubmit}>
      <FormFields
        educationEmploymentStatuses={educationEmploymentStatuses}
        saveButtonDescription={saveButtonDescription}
        completeButtonDescription={completeButtonDescription}
      />

      <Form.ActionBar
        right={
          <>
            <Form.CancelButton
              onClick={() =>
                redirectTo(high_fidelity_wrap_child_and_family_team_meeting_path(childAndFamilyTeamMeeting.id))
              }
            />
            <Form.SubmitButton label="Save" />
          </>
        }
      />
    </Form>
  )
}

EditForm.propTypes = {
  childAndFamilyTeamMeeting: types.highFidelityWrapChildAndFamilyTeamMeeting.isRequired,
  educationEmploymentStatuses: PropTypes.arrayOf(types.selectOption).isRequired,
  saveButtonDescription: PropTypes.string.isRequired,
  completeButtonDescription: PropTypes.string.isRequired,
}

export default EditForm
