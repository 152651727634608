import PropTypes from "prop-types"

import NeedFormFields from "./NeedFormFields"

import Form from "~/design/forms"
import types from "~/types"

function FormFields({ educationEmploymentStatuses, saveButtonDescription, completeButtonDescription }) {
  const rating = [1, 2, 3, 4, 5].map((number) => ({
    label: `${number}`,
    value: number,
  }))
  const numberOptions = [...Array(11).keys()].map(String)
  const missedDays = [...numberOptions, "10+"].map((item) => ({
    label: item,
    value: item,
  }))

  return (
    <>
      <Form.Fieldset title="Child and Family Team Meeting">
        <Form.Group name="meetingDate">
          <Form.Label>When did this Child and Family Team Meeting take place?</Form.Label>
          <Form.DatePicker />
        </Form.Group>
      </Form.Fieldset>

      <Form.Fieldset title="Family Vision">
        <Form.Group name="familyVision">
          <Form.Label>Family Vision</Form.Label>
          <Form.TextArea />
        </Form.Group>

        <Form.Group name="familyVisionRating">
          <Form.Label>Family Vision Rating</Form.Label>
          <Form.SelectBox options={rating} />
        </Form.Group>
      </Form.Fieldset>

      <Form.Fieldset title="Underlying Needs">
        <NeedFormFields />
      </Form.Fieldset>

      <Form.Fieldset title="School and Work">
        <Form.Group name="educationEmploymentStatusId">
          <Form.Label>Is the member currently attending school or employed?</Form.Label>
          <Form.SelectBox options={educationEmploymentStatuses} />
        </Form.Group>

        {/* HighFidelityWrap::ChildAndFamilyTeamMeeting::EducationEmploymentStatus::SCHOOL */}
        <Form.Show when={({ values }) => values.educationEmploymentStatusId === 1}>
          <Form.Group name="schoolMissedDays">
            <Form.Label>How many days of school since the last CFTM has the member NOT been able to attend?</Form.Label>
            <Form.SelectBox options={missedDays} />
          </Form.Group>
        </Form.Show>

        {/* HighFidelityWrap::ChildAndFamilyTeamMeeting::EducationEmploymentStatus::EMPLOYED */}
        <Form.Show when={({ values }) => values.educationEmploymentStatusId === 2}>
          <Form.Group name="workMissedDays">
            <Form.Label>How many days of work since the last CFTM has the member NOT been able to attend?</Form.Label>
            <Form.SelectBox options={missedDays} />
          </Form.Group>
        </Form.Show>

        {/* HighFidelityWrap::ChildAndFamilyTeamMeeting::EducationEmploymentStatus::NEITHER */}
        <Form.Show when={({ values }) => values.educationEmploymentStatusId === 3}>
          <Form.Group name="schoolWorkContext">
            <Form.Label>
              Please provide additional context on the member's school, employment, and other commitments they may have.
            </Form.Label>
            <Form.TextArea />
          </Form.Group>
        </Form.Show>
      </Form.Fieldset>

      <Form.Fieldset title="Complete">
        <p>{saveButtonDescription}</p>
        <p>{completeButtonDescription}</p>
      </Form.Fieldset>
    </>
  )
}

FormFields.propTypes = {
  educationEmploymentStatuses: PropTypes.arrayOf(types.selectOption).isRequired,
  saveButtonDescription: PropTypes.string.isRequired,
  completeButtonDescription: PropTypes.string.isRequired,
}

export default FormFields
