import { Form as BootstrapForm } from "react-bootstrap"
import ReactQuill from "react-quill"
import classNames from "classnames"
import DOMPurify from "dompurify"
import { useField } from "formik"
import PropTypes from "prop-types"

import "react-quill/dist/quill.snow.css"

import FieldErrors from "~/design/forms/FieldErrors"
import HelpText from "~/design/forms/HelpText"
import useDisableContext from "~/design/forms/useDisableContext"
import useErrorContext, { ErrorProvider } from "~/design/forms/useErrorContext"
import useNameContext, { NameProvider } from "~/design/forms/useNameContext"

const DEFAULT_MODULES = {
  toolbar: [["bold", "italic", "underline"], [{ list: "ordered" }, { list: "bullet" }], ["clean"]],
}

/**
 * A `<RichTextField />` renders a WYSIWIG text editor that allows users to input formatted text.
 */
function RichTextField({ disabled, errorKey, helpText, label, labelHidden, name, placeholder, validate }) {
  return (
    <NameProvider name={name}>
      <ErrorProvider errorKey={errorKey} name={name}>
        <BootstrapForm.Group>
          <RichTextFieldLabel hidden={labelHidden}>{label}</RichTextFieldLabel>
          <RichTextFieldEditor disabled={disabled} helpText={helpText} placeholder={placeholder} validate={validate} />
        </BootstrapForm.Group>
      </ErrorProvider>
    </NameProvider>
  )
}

function RichTextFieldEditor({ disabled: disabledByProps, helpText, placeholder, validate }) {
  const { id, name } = useNameContext()
  const [field, , helpers] = useField({ name, validate })
  const { hasError } = useErrorContext()
  const disabledByContext = useDisableContext()

  const helpTextId = `${id}HelpText`
  const errorMessageId = `${id}Errors`
  const disabled = disabledByContext || disabledByProps

  return (
    <div
      aria-labelledby={`${id}Label`}
      aria-describedby={classNames({ [helpTextId]: helpText, [errorMessageId]: hasError })}
      className={classNames("rich-text-field", {
        "rich-text-field--invalid": Boolean(hasError),
        "rich-text-field--disabled": disabled,
      })}
    >
      <ReactQuill
        id={id}
        modules={DEFAULT_MODULES}
        onChange={(newValue) => helpers.setValue(newValue)}
        placeholder={placeholder}
        readOnly={disabled}
        value={DOMPurify.sanitize(field.value)}
      />
      <HelpText id={helpTextId}>{helpText}</HelpText>
      <FieldErrors id={errorMessageId} />
    </div>
  )
}

function RichTextFieldLabel({ children, hidden }) {
  const { id } = useNameContext()
  const hiddenClass = ["sr-only", hidden].filter((e) => typeof e === "string").join("-")

  return (
    <div
      className={classNames("mb-1", { [hiddenClass]: hidden })}
      id={`${id}Label`}
      style={{ overflowWrap: "anywhere" }}
      data-testid="rich-text-field-label"
    >
      {children}
    </div>
  )
}

RichTextField.propTypes = {
  disabled: PropTypes.bool,
  /**
   * A custom value to use when looking for errors returned by the server.
   *
   * This is useful for scenarios such as presence validations on an
   * association rather than a foreign key. This can cause the errors to
   * be returned under the association name rather than the field name
   * e.g. endDateReasonCode rather than endDateReasonCodeId.
   */
  errorKey: PropTypes.string,
  /**
   * Text intended to guide the user toward the correct input.
   */
  helpText: PropTypes.string,
  /**
   * Text to label the field in lieu of a `<Label />` component.
   * (we can't use a `<Label />` because this isn't a HTML form control)
   */
  label: PropTypes.string.isRequired,
  /**
   * Whether to display the label. Use `true` to hide the label always,
   * or use a breakpoint to specify that labels should be hidden above a
   * particular screen size.
   *
   * All form controls need an associated label for accessibility, so this
   * option allows you to include a label that is not visually displayed but
   * is still findable by screen readers.
   */
  labelHidden: PropTypes.oneOf([false, true, "sm", "md", "lg", "xl"]),
  /**
   * The name of the field as assigned by the parent Formik form.
   */
  name: PropTypes.string.isRequired,
  /**
   * Representative text to display in the input before user input.
   */
  placeholder: PropTypes.string,
  /**
   * An optional function to run client-side validations:
   * https://formik.org/docs/api/field#validate
   */
  validate: PropTypes.func,
}

RichTextFieldEditor.propTypes = {
  disabled: PropTypes.bool,
  helpText: PropTypes.string,
  placeholder: PropTypes.string,
  validate: PropTypes.func,
}

RichTextFieldLabel.propTypes = {
  children: PropTypes.node.isRequired,
  hidden: PropTypes.bool,
}

RichTextFieldLabel.defaultProps = {
  hidden: false,
}

export default RichTextField
