import { default as accessibleViaPatients } from "~/services/api/accessibleViaPatients"
import { default as assessments } from "~/services/api/assessments"
import { default as bulkBarriersUpdates } from "~/services/api/bulkBarriersUpdates"
import { default as bulkDiagnosesUpdates } from "~/services/api/bulkDiagnosesUpdates"
import { default as bulkMedicationsUpdates } from "~/services/api/bulkMedicationsUpdates"
import { default as bulkRiskFactorsUpdates } from "~/services/api/bulkRiskFactorsUpdates"
import { default as bulkStrengthsUpdates } from "~/services/api/bulkStrengthsUpdates"
import { default as careManagementAgencyQualities } from "~/services/api/careManagementAgencyQualities"
import { default as carePlans } from "~/services/api/carePlans"
import { default as careTeamMemberships } from "~/services/api/careTeamMemberships"
import { default as careTeamProviders } from "~/services/api/careTeamProviders"
import { default as cesTools } from "~/services/api/cesTools"
import { default as chartedDataCopies } from "~/services/api/chartedDataCopies"
import { default as chartSearches } from "~/services/api/chartSearches"
import { default as childrensHCBSWorkflowDisenrollments } from "~/services/api/childrensHCBSWorkflowDisenrollments"
import { default as childrensHCBSWorkflowDocuments } from "~/services/api/childrensHCBSWorkflowDocuments"
import { default as childrensHCBSWorkflowLinkableDocuments } from "~/services/api/childrensHCBSWorkflowLinkableDocuments"
import { default as childrensHCBSWorkflowLinkedDocuments } from "~/services/api/childrensHCBSWorkflowLinkedDocuments"
import { default as customAssessments } from "~/services/api/customAssessments"
import { default as documents } from "~/services/api/documents"
import { default as doh5055 } from "~/services/api/doh5055"
import { default as encounters } from "~/services/api/encounters"
import { default as healthHomeConsents } from "~/services/api/healthHomeConsents"
import { default as healthHomeOptOuts } from "~/services/api/healthHomeOptOuts"
import { default as healthHomeQualities } from "~/services/api/healthHomeQualities"
import { default as highFidelityWrap } from "~/services/api/highFidelityWrap"
import { default as mcoGapsInCare } from "~/services/api/mcoGapsInCare"
import { default as patientFlags } from "~/services/api/patientFlags"
import { default as pdfAssessments } from "~/services/api/pdfAssessments"
import { default as segments } from "~/services/api/segments"
import { default as sessions } from "~/services/api/sessions"
import { default as supervisoryTeams } from "~/services/api/supervisoryTeams"
import { default as transitionsOfCare } from "~/services/api/transitionsOfCare"

export default {
  accessibleViaPatients,
  assessments,
  bulkBarriersUpdates,
  bulkDiagnosesUpdates,
  bulkMedicationsUpdates,
  bulkRiskFactorsUpdates,
  bulkStrengthsUpdates,
  careManagementAgencyQualities,
  carePlans,
  careTeamMemberships,
  careTeamProviders,
  cesTools,
  chartedDataCopies,
  chartSearches,
  childrensHCBSWorkflowDisenrollments,
  childrensHCBSWorkflowDocuments,
  childrensHCBSWorkflowLinkableDocuments,
  childrensHCBSWorkflowLinkedDocuments,
  customAssessments,
  documents,
  doh5055,
  encounters,
  healthHomeConsents,
  healthHomeOptOuts,
  healthHomeQualities,
  highFidelityWrap,
  mcoGapsInCare,
  patientFlags,
  pdfAssessments,
  segments,
  sessions,
  supervisoryTeams,
  transitionsOfCare,
}
