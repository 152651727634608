/* eslint-disable react/no-danger */

import DOMPurify from "dompurify"
import PropTypes from "prop-types"

const HIGHLIGHT_TAG = "strong"

export function FormattedEncounterNotes({ notes, searchTerm }) {
  const highlightedNotes = highlightSearchTerm(notes, searchTerm)

  return (
    <span
      dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(highlightedNotes, { ALLOWED_TAGS: [HIGHLIGHT_TAG] }) }}
    />
  )
}

FormattedEncounterNotes.propTypes = {
  notes: PropTypes.string.isRequired,
  searchTerm: PropTypes.string,
}

export function TruncatedFormattedEncounterNotes({ notes, searchTerm }) {
  const highlightedNotes = notesTeaser(notes, searchTerm)

  return (
    <span
      dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(highlightedNotes, { ALLOWED_TAGS: [HIGHLIGHT_TAG] }) }}
    />
  )
}

TruncatedFormattedEncounterNotes.propTypes = {
  notes: PropTypes.string.isRequired,
  searchTerm: PropTypes.string,
}

export const TEASER_LENGTH = 402

export function notesTeaser(notes, searchTerm) {
  if (notes.length === 0) {
    return ""
  }

  const upperCaseNotes = notes.toUpperCase()
  const upperCaseSearchTerm = searchTerm && searchTerm.toUpperCase()
  const searchTermFirstLocation = upperCaseNotes.indexOf(upperCaseSearchTerm)

  if (searchTermFirstLocation > -1) {
    return teaserWithHighlightedTerm(notes, searchTerm, searchTermFirstLocation)
  }

  return teaserWithoutHighlightedSearchTerm(notes)
}

export function teaserWithHighlightedTerm(notes, searchTerm, searchTermFirstLocation) {
  if (searchTermFirstLocation < TEASER_LENGTH) {
    const teaser = notes.slice(0, TEASER_LENGTH)
    return `${highlightSearchTerm(teaser, searchTerm)} ...`
  }

  const start = searchTermFirstLocation - TEASER_LENGTH / 2
  const end = searchTermFirstLocation + (TEASER_LENGTH / 2 - 1)
  const teaser = notes.slice(start, end)

  return `... ${highlightSearchTerm(teaser, searchTerm)} ...`
}

export function teaserWithoutHighlightedSearchTerm(notes) {
  if (notes.length > TEASER_LENGTH) {
    return `${notes.slice(0, TEASER_LENGTH)} ...`
  }

  return notes
}

export function highlightSearchTerm(notes, searchTerm) {
  const regex = new RegExp(searchTerm, "gi")

  return notes.replace(regex, (str) => `<${HIGHLIGHT_TAG}>${str}</${HIGHLIGHT_TAG}>`)
}
