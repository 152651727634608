import { Col, Row } from "react-bootstrap"
import PropTypes from "prop-types"

import ActiveReferralNote from "./ActiveReferralNote"
import CareTeamProviderDetails from "./CareTeamProviderDetails"

import Form from "~/design/forms"
import Modal from "~/design/Modal"
import api from "~/services/api"
import { toast } from "~/toast"
import types from "~/types"

function EditCareTeamMembershipForm({ membership, providerTypes, onClose, onUpdate, show }) {
  async function onSubmit(formData, { setErrors, setStatus }) {
    try {
      const response = await api.careTeamMemberships.patch(membership.id, formData)
      onUpdate(response)
      toast.success("Care team member updated.")
      setStatus("success")
      onClose()
    } catch ({ errors }) {
      setErrors(errors)
    }
  }

  return (
    <Modal
      show={show}
      onClose={onClose}
      title="Edit Care Team Member"
      size="lg"
      dataTestId="edit-care-team-membership-modal"
    >
      <Form.Provider formId="edit-care-team-membership-modal-form" initialValues={membership} onSubmit={onSubmit}>
        <Modal.Body>
          <Form.Body vertical>
            <Row>
              <Col md={6}>
                <div className="text-muted">Provider</div>
                <CareTeamProviderDetails provider={membership.provider} />
                <ActiveReferralNote />
              </Col>
              <Col md={6}>
                <Form.Group name="providerTypeId">
                  <Form.Label>Role</Form.Label>
                  <Form.SelectBox options={providerTypes} />
                </Form.Group>
                <Form.Group name="startDate">
                  <Form.Label>Start</Form.Label>
                  <Form.TextField />
                </Form.Group>
                <Form.Group name="endDate">
                  <Form.Label>End</Form.Label>
                  <Form.TextField />
                </Form.Group>
                <Form.Group name="strengths">
                  <Form.Label>Care Team Member’s Strengths</Form.Label>
                  <Form.TextArea />
                </Form.Group>
                <Form.Group name="active">
                  <Form.CheckBox label="Active" />
                </Form.Group>
                <Form.Group name="followupNeeded">
                  <Form.CheckBox label="Followup Needed" />
                </Form.Group>
              </Col>
            </Row>
          </Form.Body>
        </Modal.Body>
        <Modal.Footer>
          <Form.CancelButton onClick={onClose} />
          <Form.SubmitButton label="Save" />
        </Modal.Footer>
      </Form.Provider>
    </Modal>
  )
}

EditCareTeamMembershipForm.propTypes = {
  membership: types.careTeamMembership.isRequired,
  providerTypes: PropTypes.arrayOf(types.selectOption).isRequired,
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
}

export default EditCareTeamMembershipForm
