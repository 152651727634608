/**
 * File generated by js-routes 2.2.4
 * Based on Rails 7.0.8.7 routes of HealthHomes::Application
 */
const __jsr = (() => {
    const hasProp = (value, key) => Object.prototype.hasOwnProperty.call(value, key);
    let NodeTypes;
    (function (NodeTypes) {
        NodeTypes[NodeTypes["GROUP"] = 1] = "GROUP";
        NodeTypes[NodeTypes["CAT"] = 2] = "CAT";
        NodeTypes[NodeTypes["SYMBOL"] = 3] = "SYMBOL";
        NodeTypes[NodeTypes["OR"] = 4] = "OR";
        NodeTypes[NodeTypes["STAR"] = 5] = "STAR";
        NodeTypes[NodeTypes["LITERAL"] = 6] = "LITERAL";
        NodeTypes[NodeTypes["SLASH"] = 7] = "SLASH";
        NodeTypes[NodeTypes["DOT"] = 8] = "DOT";
    })(NodeTypes || (NodeTypes = {}));
    const isBrowser = typeof window !== "undefined";
    const ModuleReferences = {
        CJS: {
            define(routes) {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                module.exports = routes;
            },
            isSupported() {
                return typeof module === "object";
            },
        },
        AMD: {
            define(routes) {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                define([], function () {
                    return routes;
                });
            },
            isSupported() {
                return typeof define === "function" && !!define.amd;
            },
        },
        UMD: {
            define(routes) {
                if (ModuleReferences.AMD.isSupported()) {
                    ModuleReferences.AMD.define(routes);
                }
                else {
                    if (ModuleReferences.CJS.isSupported()) {
                        try {
                            ModuleReferences.CJS.define(routes);
                        }
                        catch (error) {
                            if (error.name !== "TypeError")
                                throw error;
                        }
                    }
                }
            },
            isSupported() {
                return (ModuleReferences.AMD.isSupported() ||
                    ModuleReferences.CJS.isSupported());
            },
        },
        ESM: {
            define() {
                // Module can only be defined using ruby code generation
            },
            isSupported() {
                // Its impossible to check if "export" keyword is supported
                return true;
            },
        },
        NIL: {
            define() {
                // Defined using const __jsr = 
            },
            isSupported() {
                return true;
            },
        },
        DTS: {
            // Acts the same as ESM
            define(routes) {
                ModuleReferences.ESM.define(routes);
            },
            isSupported() {
                return ModuleReferences.ESM.isSupported();
            },
        },
    };
    class ParametersMissing extends Error {
        constructor(...keys) {
            super(`Route missing required keys: ${keys.join(", ")}`);
            this.keys = keys;
            Object.setPrototypeOf(this, Object.getPrototypeOf(this));
            this.name = ParametersMissing.name;
        }
    }
    const UriEncoderSegmentRegex = /[^a-zA-Z0-9\-._~!$&'()*+,;=:@]/g;
    const ReservedOptions = [
        "anchor",
        "trailing_slash",
        "subdomain",
        "host",
        "port",
        "protocol",
    ];
    class UtilsClass {
        constructor() {
            this.configuration = {
                prefix: "",
                default_url_options: {},
                special_options_key: "_options",
                serializer: null || this.default_serializer.bind(this),
            };
        }
        default_serializer(value, prefix) {
            if (this.is_nullable(value)) {
                return "";
            }
            if (!prefix && !this.is_object(value)) {
                throw new Error("Url parameters should be a javascript hash");
            }
            prefix = prefix || "";
            const result = [];
            if (this.is_array(value)) {
                for (const element of value) {
                    result.push(this.default_serializer(element, prefix + "[]"));
                }
            }
            else if (this.is_object(value)) {
                for (let key in value) {
                    if (!hasProp(value, key))
                        continue;
                    let prop = value[key];
                    if (this.is_nullable(prop) && prefix) {
                        prop = "";
                    }
                    if (this.is_not_nullable(prop)) {
                        if (prefix) {
                            key = prefix + "[" + key + "]";
                        }
                        result.push(this.default_serializer(prop, key));
                    }
                }
            }
            else {
                if (this.is_not_nullable(value)) {
                    result.push(encodeURIComponent(prefix) + "=" + encodeURIComponent("" + value));
                }
            }
            return result.join("&");
        }
        serialize(object) {
            return this.configuration.serializer(object);
        }
        extract_options(number_of_params, args) {
            const last_el = args[args.length - 1];
            if ((args.length > number_of_params && last_el === 0) ||
                (this.is_object(last_el) &&
                    !this.looks_like_serialized_model(last_el))) {
                if (this.is_object(last_el)) {
                    delete last_el[this.configuration.special_options_key];
                }
                return {
                    args: args.slice(0, args.length - 1),
                    options: last_el,
                };
            }
            else {
                return { args, options: {} };
            }
        }
        looks_like_serialized_model(object) {
            return (this.is_object(object) &&
                !(this.configuration.special_options_key in object) &&
                ("id" in object || "to_param" in object || "toParam" in object));
        }
        path_identifier(object) {
            const result = this.unwrap_path_identifier(object);
            return this.is_nullable(result) || result === false ? "" : "" + result;
        }
        unwrap_path_identifier(object) {
            let result = object;
            if (!this.is_object(object)) {
                return object;
            }
            if ("to_param" in object) {
                result = object.to_param;
            }
            else if ("toParam" in object) {
                result = object.toParam;
            }
            else if ("id" in object) {
                result = object.id;
            }
            else {
                result = object;
            }
            return this.is_callable(result) ? result.call(object) : result;
        }
        partition_parameters(parts, required_params, default_options, call_arguments) {
            // eslint-disable-next-line prefer-const
            let { args, options } = this.extract_options(parts.length, call_arguments);
            if (args.length > parts.length) {
                throw new Error("Too many parameters provided for path");
            }
            let use_all_parts = args.length > required_params.length;
            const parts_options = {
                ...this.configuration.default_url_options,
            };
            for (const key in options) {
                const value = options[key];
                if (!hasProp(options, key))
                    continue;
                use_all_parts = true;
                if (parts.includes(key)) {
                    parts_options[key] = value;
                }
            }
            options = {
                ...this.configuration.default_url_options,
                ...default_options,
                ...options,
            };
            const keyword_parameters = {};
            let query_parameters = {};
            for (const key in options) {
                if (!hasProp(options, key))
                    continue;
                const value = options[key];
                if (key === "params") {
                    if (this.is_object(value)) {
                        query_parameters = {
                            ...query_parameters,
                            ...value,
                        };
                    }
                    else {
                        throw new Error("params value should always be an object");
                    }
                }
                else if (this.is_reserved_option(key)) {
                    keyword_parameters[key] = value;
                }
                else {
                    if (!this.is_nullable(value) &&
                        (value !== default_options[key] || required_params.includes(key))) {
                        query_parameters[key] = value;
                    }
                }
            }
            const route_parts = use_all_parts ? parts : required_params;
            let i = 0;
            for (const part of route_parts) {
                if (i < args.length) {
                    const value = args[i];
                    if (!hasProp(parts_options, part)) {
                        query_parameters[part] = value;
                        ++i;
                    }
                }
            }
            return { keyword_parameters, query_parameters };
        }
        build_route(parts, required_params, default_options, route, absolute, args) {
            const { keyword_parameters, query_parameters, } = this.partition_parameters(parts, required_params, default_options, args);
            const missing_params = required_params.filter((param) => !hasProp(query_parameters, param) ||
                this.is_nullable(query_parameters[param]));
            if (missing_params.length) {
                throw new ParametersMissing(...missing_params);
            }
            let result = this.get_prefix() + this.visit(route, query_parameters);
            if (keyword_parameters.trailing_slash) {
                result = result.replace(/(.*?)[/]?$/, "$1/");
            }
            const url_params = this.serialize(query_parameters);
            if (url_params.length) {
                result += "?" + url_params;
            }
            result += keyword_parameters.anchor
                ? "#" + keyword_parameters.anchor
                : "";
            if (absolute) {
                result = this.route_url(keyword_parameters) + result;
            }
            return result;
        }
        visit(route, parameters, optional = false) {
            switch (route[0]) {
                case NodeTypes.GROUP:
                    return this.visit(route[1], parameters, true);
                case NodeTypes.CAT:
                    return this.visit_cat(route, parameters, optional);
                case NodeTypes.SYMBOL:
                    return this.visit_symbol(route, parameters, optional);
                case NodeTypes.STAR:
                    return this.visit_globbing(route[1], parameters, true);
                case NodeTypes.LITERAL:
                case NodeTypes.SLASH:
                case NodeTypes.DOT:
                    return route[1];
                default:
                    throw new Error("Unknown Rails node type");
            }
        }
        is_not_nullable(object) {
            return !this.is_nullable(object);
        }
        is_nullable(object) {
            return object === undefined || object === null;
        }
        visit_cat(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        [_type, left, right], parameters, optional) {
            const left_part = this.visit(left, parameters, optional);
            let right_part = this.visit(right, parameters, optional);
            if (optional &&
                ((this.is_optional_node(left[0]) && !left_part) ||
                    (this.is_optional_node(right[0]) && !right_part))) {
                return "";
            }
            // if left_part ends on '/' and right_part starts on '/'
            if (left_part[left_part.length - 1] === "/" && right_part[0] === "/") {
                // strip slash from right_part
                // to prevent double slash
                right_part = right_part.substring(1);
            }
            return left_part + right_part;
        }
        visit_symbol(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        [_type, key], parameters, optional) {
            const value = this.path_identifier(parameters[key]);
            delete parameters[key];
            if (value.length) {
                return this.encode_segment(value);
            }
            if (optional) {
                return "";
            }
            else {
                throw new ParametersMissing(key);
            }
        }
        encode_segment(segment) {
            return segment.replace(UriEncoderSegmentRegex, (str) => encodeURIComponent(str));
        }
        is_optional_node(node) {
            return [NodeTypes.STAR, NodeTypes.SYMBOL, NodeTypes.CAT].includes(node);
        }
        build_path_spec(route, wildcard = false) {
            let key;
            switch (route[0]) {
                case NodeTypes.GROUP:
                    return "(" + this.build_path_spec(route[1]) + ")";
                case NodeTypes.CAT:
                    return (this.build_path_spec(route[1]) + this.build_path_spec(route[2]));
                case NodeTypes.STAR:
                    return this.build_path_spec(route[1], true);
                case NodeTypes.SYMBOL:
                    key = route[1];
                    if (wildcard) {
                        return (key.startsWith("*") ? "" : "*") + key;
                    }
                    else {
                        return ":" + key;
                    }
                    break;
                case NodeTypes.SLASH:
                case NodeTypes.DOT:
                case NodeTypes.LITERAL:
                    return route[1];
                default:
                    throw new Error("Unknown Rails node type");
            }
        }
        visit_globbing(route, parameters, optional) {
            const key = route[1];
            let value = parameters[key];
            delete parameters[key];
            if (this.is_nullable(value)) {
                return this.visit(route, parameters, optional);
            }
            if (this.is_array(value)) {
                value = value.join("/");
            }
            const result = this.path_identifier(value);
            return false
                ? result
                : encodeURI(result);
        }
        get_prefix() {
            const prefix = this.configuration.prefix;
            return prefix.match("/$")
                ? prefix.substring(0, prefix.length - 1)
                : prefix;
        }
        route(parts_table, route_spec, absolute = false) {
            const required_params = [];
            const parts = [];
            const default_options = {};
            for (const [part, { r: required, d: value }] of Object.entries(parts_table)) {
                parts.push(part);
                if (required) {
                    required_params.push(part);
                }
                if (this.is_not_nullable(value)) {
                    default_options[part] = value;
                }
            }
            const result = (...args) => {
                return this.build_route(parts, required_params, default_options, route_spec, absolute, args);
            };
            result.requiredParams = () => required_params;
            result.toString = () => {
                return this.build_path_spec(route_spec);
            };
            return result;
        }
        route_url(route_defaults) {
            const hostname = route_defaults.host || this.current_host();
            if (!hostname) {
                return "";
            }
            const subdomain = route_defaults.subdomain
                ? route_defaults.subdomain + "."
                : "";
            const protocol = route_defaults.protocol || this.current_protocol();
            let port = route_defaults.port ||
                (!route_defaults.host ? this.current_port() : undefined);
            port = port ? ":" + port : "";
            return protocol + "://" + subdomain + hostname + port;
        }
        current_host() {
            var _a;
            return (isBrowser && ((_a = window === null || window === void 0 ? void 0 : window.location) === null || _a === void 0 ? void 0 : _a.hostname)) || "";
        }
        current_protocol() {
            var _a, _b;
            return ((isBrowser && ((_b = (_a = window === null || window === void 0 ? void 0 : window.location) === null || _a === void 0 ? void 0 : _a.protocol) === null || _b === void 0 ? void 0 : _b.replace(/:$/, ""))) || "http");
        }
        current_port() {
            var _a;
            return (isBrowser && ((_a = window === null || window === void 0 ? void 0 : window.location) === null || _a === void 0 ? void 0 : _a.port)) || "";
        }
        is_object(value) {
            return (typeof value === "object" &&
                Object.prototype.toString.call(value) === "[object Object]");
        }
        is_array(object) {
            return object instanceof Array;
        }
        is_callable(object) {
            return typeof object === "function" && !!object.call;
        }
        is_reserved_option(key) {
            return ReservedOptions.includes(key);
        }
        configure(new_config) {
            this.configuration = { ...this.configuration, ...new_config };
            return this.configuration;
        }
        config() {
            return { ...this.configuration };
        }
        is_module_supported(name) {
            return ModuleReferences[name].isSupported();
        }
        ensure_module_supported(name) {
            if (!this.is_module_supported(name)) {
                throw new Error(`${name} is not supported by runtime`);
            }
        }
        define_module(name, module) {
            this.ensure_module_supported(name);
            ModuleReferences[name].define(module);
        }
    }
    const Utils = new UtilsClass();
    // We want this helper name to be short
    const __jsr = {
        r(parts_table, route_spec, absolute) {
            return Utils.route(parts_table, route_spec, absolute);
        },
    };
    const result = {
        ...__jsr,
        configure: (config) => {
            return Utils.configure(config);
        },
        config: () => {
            return Utils.config();
        },
        serialize: (object) => {
            return Utils.serialize(object);
        },
        ...{},
    };
    Utils.define_module("ESM", result);
    return result;
})();
export const configure = __jsr.configure;

export const config = __jsr.config;

export const serialize = __jsr.serialize;

/**
 * Generates rails route to
 * /accessible_external_assessments/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const accessible_external_assessment_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"accessible_external_assessments"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /accessible_external_care_plans/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const accessible_external_care_plan_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"accessible_external_care_plans"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /accessible_external_documents/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const accessible_external_document_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"accessible_external_documents"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /accessible_external_encounters/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const accessible_external_encounter_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"accessible_external_encounters"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /accessible_external_health_home_consents/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const accessible_external_health_home_consent_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"accessible_external_health_home_consents"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /accessible_external_health_home_opt_outs/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const accessible_external_health_home_opt_out_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"accessible_external_health_home_opt_outs"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /accessible_external_pdf_assessments/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const accessible_external_pdf_assessment_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"accessible_external_pdf_assessments"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/adult_caseload_overview_exports(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_adult_caseload_overview_exports_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"adult_caseload_overview_exports"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/base_assessment_exports(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_base_assessment_exports_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"base_assessment_exports"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/bulk_barriers_updates(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_bulk_barriers_updates_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"bulk_barriers_updates"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/bulk_diagnoses_update(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_bulk_diagnoses_update_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"bulk_diagnoses_update"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/bulk_medications_update(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_bulk_medications_update_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"bulk_medications_update"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/bulk_risk_factors_updates(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_bulk_risk_factors_updates_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"bulk_risk_factors_updates"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/bulk_strengths_updates(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_bulk_strengths_updates_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"bulk_strengths_updates"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/care_management_agencies/:care_management_agency_id/care_management_agency_quality_dashboard_exports(.:format)
 * @param {any} care_management_agency_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_care_management_agency_care_management_agency_quality_dashboard_exports_path = __jsr.r({"care_management_agency_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"care_management_agencies"],[2,[7,"/"],[2,[3,"care_management_agency_id"],[2,[7,"/"],[2,[6,"care_management_agency_quality_dashboard_exports"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/care_management_agencies/:care_management_agency_id/quality_dashboard(.:format)
 * @param {any} care_management_agency_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_care_management_agency_quality_dashboard_path = __jsr.r({"care_management_agency_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"care_management_agencies"],[2,[7,"/"],[2,[3,"care_management_agency_id"],[2,[7,"/"],[2,[6,"quality_dashboard"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/care_plans/:care_plan_id/care_plan_needs(.:format)
 * @param {any} care_plan_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_care_plan_care_plan_needs_path = __jsr.r({"care_plan_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"care_plans"],[2,[7,"/"],[2,[3,"care_plan_id"],[2,[7,"/"],[2,[6,"care_plan_needs"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/care_plans/:care_plan_id/care_plan_signatures(.:format)
 * @param {any} care_plan_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_care_plan_care_plan_signatures_path = __jsr.r({"care_plan_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"care_plans"],[2,[7,"/"],[2,[3,"care_plan_id"],[2,[7,"/"],[2,[6,"care_plan_signatures"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/care_plan_goals/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_care_plan_goal_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"care_plan_goals"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/care_plan_goals/:care_plan_goal_id/care_plan_tasks(.:format)
 * @param {any} care_plan_goal_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_care_plan_goal_care_plan_tasks_path = __jsr.r({"care_plan_goal_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"care_plan_goals"],[2,[7,"/"],[2,[3,"care_plan_goal_id"],[2,[7,"/"],[2,[6,"care_plan_tasks"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/care_plan_needs/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_care_plan_need_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"care_plan_needs"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/care_plan_needs/:care_plan_need_id/care_plan_goals(.:format)
 * @param {any} care_plan_need_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_care_plan_need_care_plan_goals_path = __jsr.r({"care_plan_need_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"care_plan_needs"],[2,[7,"/"],[2,[3,"care_plan_need_id"],[2,[7,"/"],[2,[6,"care_plan_goals"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/care_plan_tasks/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_care_plan_task_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"care_plan_tasks"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/care_team_memberships/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_care_team_membership_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"care_team_memberships"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/care_team_memberships(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_care_team_memberships_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"care_team_memberships"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/care_team_providers(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_care_team_providers_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"care_team_providers"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/ces_tools/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_ces_tool_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"ces_tools"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/charts/:chart_id/assessments(.:format)
 * @param {any} chart_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_chart_assessments_path = __jsr.r({"chart_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"charts"],[2,[7,"/"],[2,[3,"chart_id"],[2,[7,"/"],[2,[6,"assessments"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/charts/:chart_id/care_plans(.:format)
 * @param {any} chart_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_chart_care_plans_path = __jsr.r({"chart_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"charts"],[2,[7,"/"],[2,[3,"chart_id"],[2,[7,"/"],[2,[6,"care_plans"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/charts/:chart_id/ces_tools(.:format)
 * @param {any} chart_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_chart_ces_tools_path = __jsr.r({"chart_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"charts"],[2,[7,"/"],[2,[3,"chart_id"],[2,[7,"/"],[2,[6,"ces_tools"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/charts/:chart_id/charted_data_copies(.:format)
 * @param {any} chart_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_chart_charted_data_copies_path = __jsr.r({"chart_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"charts"],[2,[7,"/"],[2,[3,"chart_id"],[2,[7,"/"],[2,[6,"charted_data_copies"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/charts/:chart_id/clinical_events(.:format)
 * @param {any} chart_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_chart_clinical_events_path = __jsr.r({"chart_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"charts"],[2,[7,"/"],[2,[3,"chart_id"],[2,[7,"/"],[2,[6,"clinical_events"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/charts/:chart_id/documents(.:format)
 * @param {any} chart_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_chart_documents_path = __jsr.r({"chart_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"charts"],[2,[7,"/"],[2,[3,"chart_id"],[2,[7,"/"],[2,[6,"documents"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/charts/:chart_id/doh5055_forms(.:format)
 * @param {any} chart_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_chart_doh5055_forms_path = __jsr.r({"chart_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"charts"],[2,[7,"/"],[2,[3,"chart_id"],[2,[7,"/"],[2,[6,"doh5055_forms"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/charts/:chart_id/encounters(.:format)
 * @param {any} chart_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_chart_encounters_path = __jsr.r({"chart_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"charts"],[2,[7,"/"],[2,[3,"chart_id"],[2,[7,"/"],[2,[6,"encounters"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/chart_exports(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_chart_exports_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"chart_exports"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/charts/:chart_id/health_home_consents(.:format)
 * @param {any} chart_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_chart_health_home_consents_path = __jsr.r({"chart_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"charts"],[2,[7,"/"],[2,[3,"chart_id"],[2,[7,"/"],[2,[6,"health_home_consents"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/charts/:chart_id/health_home_opt_outs(.:format)
 * @param {any} chart_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_chart_health_home_opt_outs_path = __jsr.r({"chart_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"charts"],[2,[7,"/"],[2,[3,"chart_id"],[2,[7,"/"],[2,[6,"health_home_opt_outs"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/charts/:chart_id/high_fidelity_wrap/child_and_family_team_meetings(.:format)
 * @param {any} chart_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_chart_high_fidelity_wrap_child_and_family_team_meetings_path = __jsr.r({"chart_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"charts"],[2,[7,"/"],[2,[3,"chart_id"],[2,[7,"/"],[2,[6,"high_fidelity_wrap"],[2,[7,"/"],[2,[6,"child_and_family_team_meetings"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/charts/:chart_id/pdf_assessments(.:format)
 * @param {any} chart_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_chart_pdf_assessments_path = __jsr.r({"chart_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"charts"],[2,[7,"/"],[2,[3,"chart_id"],[2,[7,"/"],[2,[6,"pdf_assessments"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/chart_searches(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_chart_searches_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"chart_searches"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/charts/:chart_id/segments(.:format)
 * @param {any} chart_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_chart_segments_path = __jsr.r({"chart_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"charts"],[2,[7,"/"],[2,[3,"chart_id"],[2,[7,"/"],[2,[6,"segments"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/child_caseload_overview_exports(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_child_caseload_overview_exports_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"child_caseload_overview_exports"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/childrens_hcbs_workflows/:childrens_hcbs_workflow_id/childrens_hcbs_workflow_documents(.:format)
 * @param {any} childrens_hcbs_workflow_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_childrens_hcbs_workflow_childrens_hcbs_workflow_documents_path = __jsr.r({"childrens_hcbs_workflow_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"childrens_hcbs_workflows"],[2,[7,"/"],[2,[3,"childrens_hcbs_workflow_id"],[2,[7,"/"],[2,[6,"childrens_hcbs_workflow_documents"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/childrens_hcbs_workflows/:childrens_hcbs_workflow_id/disenrollment(.:format)
 * @param {any} childrens_hcbs_workflow_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_childrens_hcbs_workflow_disenrollment_path = __jsr.r({"childrens_hcbs_workflow_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"childrens_hcbs_workflows"],[2,[7,"/"],[2,[3,"childrens_hcbs_workflow_id"],[2,[7,"/"],[2,[6,"disenrollment"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/childrens_hcbs_workflow_documents/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_childrens_hcbs_workflow_document_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"childrens_hcbs_workflow_documents"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/childrens_hcbs_workflows/:childrens_hcbs_workflow_id/linkable_documents(.:format)
 * @param {any} childrens_hcbs_workflow_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_childrens_hcbs_workflow_linkable_documents_path = __jsr.r({"childrens_hcbs_workflow_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"childrens_hcbs_workflows"],[2,[7,"/"],[2,[3,"childrens_hcbs_workflow_id"],[2,[7,"/"],[2,[6,"linkable_documents"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/childrens_hcbs_workflows/:childrens_hcbs_workflow_id/linked_documents(.:format)
 * @param {any} childrens_hcbs_workflow_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_childrens_hcbs_workflow_linked_documents_path = __jsr.r({"childrens_hcbs_workflow_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"childrens_hcbs_workflows"],[2,[7,"/"],[2,[3,"childrens_hcbs_workflow_id"],[2,[7,"/"],[2,[6,"linked_documents"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/claim_status_report_exports(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_claim_status_report_exports_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"claim_status_report_exports"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/clinical_alert_exports(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_clinical_alert_exports_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"clinical_alert_exports"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/clinical_events/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_clinical_event_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"clinical_events"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/clinical_event_merges(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_clinical_event_merges_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"clinical_event_merges"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/custom_assessment_responses/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_custom_assessment_response_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"custom_assessment_responses"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/custom_assessment_responses/:custom_assessment_response_id/uncompletes(.:format)
 * @param {any} custom_assessment_response_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_custom_assessment_response_uncompletes_path = __jsr.r({"custom_assessment_response_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"custom_assessment_responses"],[2,[7,"/"],[2,[3,"custom_assessment_response_id"],[2,[7,"/"],[2,[6,"uncompletes"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/custom_assessment_schema_questions/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_custom_assessment_schema_question_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"custom_assessment_schema_questions"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/custom_assessment_schema_sections/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_custom_assessment_schema_section_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"custom_assessment_schema_sections"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/custom_assessment_schema_sections/:custom_assessment_schema_section_id/custom_assessment_schema_questions(.:format)
 * @param {any} custom_assessment_schema_section_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_custom_assessment_schema_section_custom_assessment_schema_questions_path = __jsr.r({"custom_assessment_schema_section_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"custom_assessment_schema_sections"],[2,[7,"/"],[2,[3,"custom_assessment_schema_section_id"],[2,[7,"/"],[2,[6,"custom_assessment_schema_questions"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/custom_assessment_schema_versions/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_custom_assessment_schema_version_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"custom_assessment_schema_versions"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/custom_assessment_schema_versions/:custom_assessment_schema_version_id/custom_assessment_schema_sections(.:format)
 * @param {any} custom_assessment_schema_version_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_custom_assessment_schema_version_custom_assessment_schema_sections_path = __jsr.r({"custom_assessment_schema_version_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"custom_assessment_schema_versions"],[2,[7,"/"],[2,[3,"custom_assessment_schema_version_id"],[2,[7,"/"],[2,[6,"custom_assessment_schema_sections"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/documents/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_document_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"documents"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/eligibility_inquiry_responses(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_eligibility_inquiry_responses_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"eligibility_inquiry_responses"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/encounters/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_encounter_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"encounters"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/encounter_exports(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_encounter_exports_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"encounter_exports"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/file_export_statuses/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_file_export_status_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"file_export_statuses"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/harp/hcbs_details_report_exports(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_harp_hcbs_details_report_exports_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"harp"],[2,[7,"/"],[2,[6,"hcbs_details_report_exports"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/harp/hcbs_summary_report_exports(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_harp_hcbs_summary_report_exports_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"harp"],[2,[7,"/"],[2,[6,"hcbs_summary_report_exports"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/health_home_consents/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_health_home_consent_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"health_home_consents"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/health_homes/:health_home_id/health_home_quality_dashboard_exports(.:format)
 * @param {any} health_home_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_health_home_health_home_quality_dashboard_exports_path = __jsr.r({"health_home_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"health_homes"],[2,[7,"/"],[2,[3,"health_home_id"],[2,[7,"/"],[2,[6,"health_home_quality_dashboard_exports"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/health_homes/:health_home_id/quality_dashboard(.:format)
 * @param {any} health_home_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_health_home_quality_dashboard_path = __jsr.r({"health_home_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"health_homes"],[2,[7,"/"],[2,[3,"health_home_id"],[2,[7,"/"],[2,[6,"quality_dashboard"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/high_fidelity_wrap/child_and_family_team_meetings/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_high_fidelity_wrap_child_and_family_team_meeting_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"high_fidelity_wrap"],[2,[7,"/"],[2,[6,"child_and_family_team_meetings"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/mco_gaps_in_care/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_mco_gap_in_care_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"mco_gaps_in_care"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/mco_gaps_in_care(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_mco_gaps_in_care_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"mco_gaps_in_care"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/mco_gaps_in_care_exports(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_mco_gaps_in_care_exports_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"mco_gaps_in_care_exports"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/accessible_via_patients/:patient_id/enrollment_records(.:format)
 * @param {any} patient_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_patient_enrollment_records_path = __jsr.r({"patient_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"accessible_via_patients"],[2,[7,"/"],[2,[3,"patient_id"],[2,[7,"/"],[2,[6,"enrollment_records"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/patient_flags/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_patient_flag_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"patient_flags"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/patient_flags(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_patient_flags_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"patient_flags"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/accessible_via_patients/:patient_id/segments(.:format)
 * @param {any} patient_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_patient_segments_path = __jsr.r({"patient_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"accessible_via_patients"],[2,[7,"/"],[2,[3,"patient_id"],[2,[7,"/"],[2,[6,"segments"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/reconciliation_exports(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_reconciliation_exports_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"reconciliation_exports"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/segments/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_segment_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"segments"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/supervisory_teams/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_supervisory_team_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"supervisory_teams"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/supervisory_teams/:supervisory_team_id/ces_tools_requiring_approval(.:format)
 * @param {any} supervisory_team_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_supervisory_team_ces_tools_requiring_approval_path = __jsr.r({"supervisory_team_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"supervisory_teams"],[2,[7,"/"],[2,[3,"supervisory_team_id"],[2,[7,"/"],[2,[6,"ces_tools_requiring_approval"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/supervisory_teams/:supervisory_team_id/members/:id(.:format)
 * @param {any} supervisory_team_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_supervisory_team_member_path = __jsr.r({"supervisory_team_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"supervisory_teams"],[2,[7,"/"],[2,[3,"supervisory_team_id"],[2,[7,"/"],[2,[6,"members"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/supervisory_teams/:supervisory_team_id/members(.:format)
 * @param {any} supervisory_team_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_supervisory_team_members_path = __jsr.r({"supervisory_team_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"supervisory_teams"],[2,[7,"/"],[2,[3,"supervisory_team_id"],[2,[7,"/"],[2,[6,"members"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/supervisory_teams/:supervisory_team_id/pending_care_plan_review_requests(.:format)
 * @param {any} supervisory_team_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_supervisory_team_pending_care_plan_review_requests_path = __jsr.r({"supervisory_team_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"supervisory_teams"],[2,[7,"/"],[2,[3,"supervisory_team_id"],[2,[7,"/"],[2,[6,"pending_care_plan_review_requests"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/supervisory_teams/:supervisory_team_id/supervisors/:id(.:format)
 * @param {any} supervisory_team_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_supervisory_team_supervisor_path = __jsr.r({"supervisory_team_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"supervisory_teams"],[2,[7,"/"],[2,[3,"supervisory_team_id"],[2,[7,"/"],[2,[6,"supervisors"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/supervisory_teams/:supervisory_team_id/supervisors(.:format)
 * @param {any} supervisory_team_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_supervisory_team_supervisors_path = __jsr.r({"supervisory_team_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"supervisory_teams"],[2,[7,"/"],[2,[3,"supervisory_team_id"],[2,[7,"/"],[2,[6,"supervisors"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/supervisory_teams(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_supervisory_teams_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"supervisory_teams"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/user_exports(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_user_exports_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"user_exports"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/work_check/assessment_download_files/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_work_check_assessment_download_file_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"work_check"],[2,[7,"/"],[2,[6,"assessment_download_files"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/work_check/billing_claim_batches/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_work_check_billing_claim_batch_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"work_check"],[2,[7,"/"],[2,[6,"billing_claim_batches"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/work_check/billing_claim_batches/:billing_claim_batch_id/billing_claim_batch_deletions(.:format)
 * @param {any} billing_claim_batch_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_work_check_billing_claim_batch_billing_claim_batch_deletions_path = __jsr.r({"billing_claim_batch_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"work_check"],[2,[7,"/"],[2,[6,"billing_claim_batches"],[2,[7,"/"],[2,[3,"billing_claim_batch_id"],[2,[7,"/"],[2,[6,"billing_claim_batch_deletions"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/work_check/billing_claim_submission_batches/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_work_check_billing_claim_submission_batch_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"work_check"],[2,[7,"/"],[2,[6,"billing_claim_submission_batches"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/work_check/billing_claim_submission_batches/:billing_claim_submission_batch_id/billing_claim_submission_batch_deletions(.:format)
 * @param {any} billing_claim_submission_batch_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_work_check_billing_claim_submission_batch_billing_claim_submission_batch_deletions_path = __jsr.r({"billing_claim_submission_batch_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"work_check"],[2,[7,"/"],[2,[6,"billing_claim_submission_batches"],[2,[7,"/"],[2,[3,"billing_claim_submission_batch_id"],[2,[7,"/"],[2,[6,"billing_claim_submission_batch_deletions"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/work_check/billing_claim_transaction_files/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_work_check_billing_claim_transaction_file_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"work_check"],[2,[7,"/"],[2,[6,"billing_claim_transaction_files"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/work_check/billing_statements/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_work_check_billing_statement_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"work_check"],[2,[7,"/"],[2,[6,"billing_statements"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/work_check/billing_statement_batches/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_work_check_billing_statement_batch_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"work_check"],[2,[7,"/"],[2,[6,"billing_statement_batches"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/work_check/billing_statements/:billing_statement_id/billing_statement_deletions(.:format)
 * @param {any} billing_statement_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_work_check_billing_statement_billing_statement_deletions_path = __jsr.r({"billing_statement_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"work_check"],[2,[7,"/"],[2,[6,"billing_statements"],[2,[7,"/"],[2,[3,"billing_statement_id"],[2,[7,"/"],[2,[6,"billing_statement_deletions"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/work_check/billing_support_rate_code_checkpoints/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_work_check_billing_support_rate_code_checkpoint_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"work_check"],[2,[7,"/"],[2,[6,"billing_support_rate_code_checkpoints"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/work_check/billing_support_void_checkpoints/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_work_check_billing_support_void_checkpoint_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"work_check"],[2,[7,"/"],[2,[6,"billing_support_void_checkpoints"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/work_check/care_plan_download_files/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_work_check_care_plan_download_file_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"work_check"],[2,[7,"/"],[2,[6,"care_plan_download_files"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/work_check/care_plan_error_files/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_work_check_care_plan_error_file_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"work_check"],[2,[7,"/"],[2,[6,"care_plan_error_files"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/work_check/care_plan_upload_files/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_work_check_care_plan_upload_file_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"work_check"],[2,[7,"/"],[2,[6,"care_plan_upload_files"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/work_check/charted_data_copies/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_work_check_charted_data_copy_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"work_check"],[2,[7,"/"],[2,[6,"charted_data_copies"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/work_check/consent_download_files/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_work_check_consent_download_file_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"work_check"],[2,[7,"/"],[2,[6,"consent_download_files"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/work_check/consent_error_files/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_work_check_consent_error_file_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"work_check"],[2,[7,"/"],[2,[6,"consent_error_files"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/work_check/consent_upload_files/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_work_check_consent_upload_file_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"work_check"],[2,[7,"/"],[2,[6,"consent_upload_files"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/work_check/error_report_uploaded_files/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_work_check_error_report_uploaded_file_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"work_check"],[2,[7,"/"],[2,[6,"error_report_uploaded_files"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/work_check/extracts/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_work_check_extract_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"work_check"],[2,[7,"/"],[2,[6,"extracts"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/work_check/import_btq_activity_files/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_work_check_import_btq_activity_file_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"work_check"],[2,[7,"/"],[2,[6,"import_btq_activity_files"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/work_check/import_clinical_alert_import_files/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_work_check_import_clinical_alert_import_file_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"work_check"],[2,[7,"/"],[2,[6,"import_clinical_alert_import_files"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/work_check/import_encounter_import_files/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_work_check_import_encounter_import_file_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"work_check"],[2,[7,"/"],[2,[6,"import_encounter_import_files"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/work_check/mco_gap_in_care_files/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_work_check_mco_gap_in_care_file_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"work_check"],[2,[7,"/"],[2,[6,"mco_gap_in_care_files"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/work_check/tracking_billing_support_download_files/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_work_check_tracking_billing_support_download_file_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"work_check"],[2,[7,"/"],[2,[6,"tracking_billing_support_download_files"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/work_check/tracking_billing_support_error_files/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_work_check_tracking_billing_support_error_file_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"work_check"],[2,[7,"/"],[2,[6,"tracking_billing_support_error_files"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/work_check/tracking_billing_support_upload_files/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_work_check_tracking_billing_support_upload_file_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"work_check"],[2,[7,"/"],[2,[6,"tracking_billing_support_upload_files"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/work_check/tracking_enrollment_files/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_work_check_tracking_enrollment_file_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"work_check"],[2,[7,"/"],[2,[6,"tracking_enrollment_files"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/work_check/tracking_patient_tracking_files/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_work_check_tracking_patient_tracking_file_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"work_check"],[2,[7,"/"],[2,[6,"tracking_patient_tracking_files"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /assessments/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const assessment_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"assessments"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /assessments/:assessment_id/complete_and_locks(.:format)
 * @param {any} assessment_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const assessment_complete_and_locks_path = __jsr.r({"assessment_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"assessments"],[2,[7,"/"],[2,[3,"assessment_id"],[2,[7,"/"],[2,[6,"complete_and_locks"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /assessment_download_files/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const assessment_download_file_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"assessment_download_files"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /assessment_download_files/:assessment_download_file_id/cest_assessment_download_records(.:format)
 * @param {any} assessment_download_file_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const assessment_download_file_cest_assessment_download_records_path = __jsr.r({"assessment_download_file_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"assessment_download_files"],[2,[7,"/"],[2,[3,"assessment_download_file_id"],[2,[7,"/"],[2,[6,"cest_assessment_download_records"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /assessment_download_files/:assessment_download_file_id/errors(.:format)
 * @param {any} assessment_download_file_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const assessment_download_file_errors_path = __jsr.r({"assessment_download_file_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"assessment_download_files"],[2,[7,"/"],[2,[3,"assessment_download_file_id"],[2,[7,"/"],[2,[6,"errors"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /assessment_download_files/:assessment_download_file_id/hcbs_assessment_download_records(.:format)
 * @param {any} assessment_download_file_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const assessment_download_file_hcbs_assessment_download_records_path = __jsr.r({"assessment_download_file_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"assessment_download_files"],[2,[7,"/"],[2,[3,"assessment_download_file_id"],[2,[7,"/"],[2,[6,"hcbs_assessment_download_records"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /assessment_download_files(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const assessment_download_files_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"assessment_download_files"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /assessments/:assessment_id/mark_as_dones(.:format)
 * @param {any} assessment_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const assessment_mark_as_dones_path = __jsr.r({"assessment_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"assessments"],[2,[7,"/"],[2,[3,"assessment_id"],[2,[7,"/"],[2,[6,"mark_as_dones"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /assessment_providers/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const assessment_provider_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"assessment_providers"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /assessments/:assessment_id/supervisor_approvals(.:format)
 * @param {any} assessment_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const assessment_supervisor_approvals_path = __jsr.r({"assessment_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"assessments"],[2,[7,"/"],[2,[3,"assessment_id"],[2,[7,"/"],[2,[6,"supervisor_approvals"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /assessments/:assessment_id/uncomplete_and_unlocks(.:format)
 * @param {any} assessment_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const assessment_uncomplete_and_unlocks_path = __jsr.r({"assessment_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"assessments"],[2,[7,"/"],[2,[3,"assessment_id"],[2,[7,"/"],[2,[6,"uncomplete_and_unlocks"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /base_assessments(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const base_assessments_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"base_assessments"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /billing(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const billing_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"billing"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /billing/admin_fees/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const billing_admin_fee_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"billing"],[2,[7,"/"],[2,[6,"admin_fees"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /billing/admin_fees(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const billing_admin_fees_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"billing"],[2,[7,"/"],[2,[6,"admin_fees"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /billing/administrative_fee_batches/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const billing_administrative_fee_batch_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"billing"],[2,[7,"/"],[2,[6,"administrative_fee_batches"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /billing/administrative_fee_batches(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const billing_administrative_fee_batches_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"billing"],[2,[7,"/"],[2,[6,"administrative_fee_batches"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /billing/amida_care_incentive_program_claim_batches/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const billing_amida_care_incentive_program_claim_batch_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"billing"],[2,[7,"/"],[2,[6,"amida_care_incentive_program_claim_batches"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /billing/amida_care_incentive_program_claim_batches(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const billing_amida_care_incentive_program_claim_batches_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"billing"],[2,[7,"/"],[2,[6,"amida_care_incentive_program_claim_batches"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /billing/api/v1/admin_fee_exports(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const billing_api_v1_admin_fee_exports_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"billing"],[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"admin_fee_exports"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /billing/api/v1/claim_exports(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const billing_api_v1_claim_exports_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"billing"],[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"claim_exports"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /billing/api/v1/claim_transaction_exports(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const billing_api_v1_claim_transaction_exports_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"billing"],[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"claim_transaction_exports"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /billing/api/v1/statement_details_exports(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const billing_api_v1_statement_details_exports_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"billing"],[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"statement_details_exports"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /billing/api/v1/statements_details_exports(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const billing_api_v1_statements_details_exports_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"billing"],[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"statements_details_exports"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /billing/bulk_claim_write_off(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const billing_bulk_claim_write_off_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"billing"],[2,[7,"/"],[2,[6,"bulk_claim_write_off"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /billing/bulk_claims(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const billing_bulk_claims_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"billing"],[2,[7,"/"],[2,[6,"bulk_claims"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /billing/claims/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const billing_claim_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"billing"],[2,[7,"/"],[2,[6,"claims"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /billing/claim_admin_flag_updates(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const billing_claim_admin_flag_updates_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"billing"],[2,[7,"/"],[2,[6,"claim_admin_flag_updates"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /billing/claim_batches/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const billing_claim_batch_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"billing"],[2,[7,"/"],[2,[6,"claim_batches"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /billing/claim_batches/:claim_batch_id/claim_batch_deletions(.:format)
 * @param {any} claim_batch_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const billing_claim_batch_claim_batch_deletions_path = __jsr.r({"claim_batch_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"billing"],[2,[7,"/"],[2,[6,"claim_batches"],[2,[7,"/"],[2,[3,"claim_batch_id"],[2,[7,"/"],[2,[6,"claim_batch_deletions"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /billing/claim_batches(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const billing_claim_batches_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"billing"],[2,[7,"/"],[2,[6,"claim_batches"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /billing/claim_submissions/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const billing_claim_submission_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"billing"],[2,[7,"/"],[2,[6,"claim_submissions"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /billing/claim_submission_batches/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const billing_claim_submission_batch_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"billing"],[2,[7,"/"],[2,[6,"claim_submission_batches"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /billing/claim_submission_batches/:claim_submission_batch_id/claim_submission_batch_deletions(.:format)
 * @param {any} claim_submission_batch_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const billing_claim_submission_batch_claim_submission_batch_deletions_path = __jsr.r({"claim_submission_batch_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"billing"],[2,[7,"/"],[2,[6,"claim_submission_batches"],[2,[7,"/"],[2,[3,"claim_submission_batch_id"],[2,[7,"/"],[2,[6,"claim_submission_batch_deletions"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /billing/claim_submission_batches(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const billing_claim_submission_batches_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"billing"],[2,[7,"/"],[2,[6,"claim_submission_batches"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /billing/claim_submissions/:claim_submission_id/mark_for_retry(.:format)
 * @param {any} claim_submission_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const billing_claim_submission_mark_for_retry_path = __jsr.r({"claim_submission_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"billing"],[2,[7,"/"],[2,[6,"claim_submissions"],[2,[7,"/"],[2,[3,"claim_submission_id"],[2,[7,"/"],[2,[6,"mark_for_retry"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /billing/claim_transaction_files/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const billing_claim_transaction_file_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"billing"],[2,[7,"/"],[2,[6,"claim_transaction_files"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /billing/claim_transaction_files/:claim_transaction_file_id/errors(.:format)
 * @param {any} claim_transaction_file_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const billing_claim_transaction_file_errors_path = __jsr.r({"claim_transaction_file_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"billing"],[2,[7,"/"],[2,[6,"claim_transaction_files"],[2,[7,"/"],[2,[3,"claim_transaction_file_id"],[2,[7,"/"],[2,[6,"errors"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /billing/claim_transaction_files/:claim_transaction_file_id/records(.:format)
 * @param {any} claim_transaction_file_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const billing_claim_transaction_file_records_path = __jsr.r({"claim_transaction_file_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"billing"],[2,[7,"/"],[2,[6,"claim_transaction_files"],[2,[7,"/"],[2,[3,"claim_transaction_file_id"],[2,[7,"/"],[2,[6,"records"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /billing/claim_transaction_files(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const billing_claim_transaction_files_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"billing"],[2,[7,"/"],[2,[6,"claim_transaction_files"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /billing/claims/:claim_id/void(.:format)
 * @param {any} claim_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const billing_claim_void_path = __jsr.r({"claim_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"billing"],[2,[7,"/"],[2,[6,"claims"],[2,[7,"/"],[2,[3,"claim_id"],[2,[7,"/"],[2,[6,"void"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /billing/claims/:claim_id/write_off(.:format)
 * @param {any} claim_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const billing_claim_write_off_path = __jsr.r({"claim_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"billing"],[2,[7,"/"],[2,[6,"claims"],[2,[7,"/"],[2,[3,"claim_id"],[2,[7,"/"],[2,[6,"write_off"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /billing/claims(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const billing_claims_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"billing"],[2,[7,"/"],[2,[6,"claims"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /billing/hcbs_eligibility_assessment_claim_batches/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const billing_hcbs_eligibility_assessment_claim_batch_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"billing"],[2,[7,"/"],[2,[6,"hcbs_eligibility_assessment_claim_batches"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /billing/hcbs_eligibility_assessment_claim_batches(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const billing_hcbs_eligibility_assessment_claim_batches_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"billing"],[2,[7,"/"],[2,[6,"hcbs_eligibility_assessment_claim_batches"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /billing/payment_summary(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const billing_payment_summary_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"billing"],[2,[7,"/"],[2,[6,"payment_summary"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /billing/reports/claim_status(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const billing_reports_claim_status_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"billing"],[2,[7,"/"],[2,[6,"reports"],[2,[7,"/"],[2,[6,"claim_status"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /billing/reports/payment_summary(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const billing_reports_payment_summary_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"billing"],[2,[7,"/"],[2,[6,"reports"],[2,[7,"/"],[2,[6,"payment_summary"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /billing/statements/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const billing_statement_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"billing"],[2,[7,"/"],[2,[6,"statements"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /billing/statement_adjustments/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const billing_statement_adjustment_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"billing"],[2,[7,"/"],[2,[6,"statement_adjustments"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /billing/statements/:statement_id/admin_fees(.:format)
 * @param {any} statement_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const billing_statement_admin_fees_path = __jsr.r({"statement_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"billing"],[2,[7,"/"],[2,[6,"statements"],[2,[7,"/"],[2,[3,"statement_id"],[2,[7,"/"],[2,[6,"admin_fees"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /billing/statement_batches/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const billing_statement_batch_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"billing"],[2,[7,"/"],[2,[6,"statement_batches"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /billing/statement_batches/:statement_batch_id/statement_email_batch(.:format)
 * @param {any} statement_batch_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const billing_statement_batch_statement_email_batch_path = __jsr.r({"statement_batch_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"billing"],[2,[7,"/"],[2,[6,"statement_batches"],[2,[7,"/"],[2,[3,"statement_batch_id"],[2,[7,"/"],[2,[6,"statement_email_batch"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /billing/statement_batches/:statement_batch_id/statement_total_recalculation_batch(.:format)
 * @param {any} statement_batch_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const billing_statement_batch_statement_total_recalculation_batch_path = __jsr.r({"statement_batch_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"billing"],[2,[7,"/"],[2,[6,"statement_batches"],[2,[7,"/"],[2,[3,"statement_batch_id"],[2,[7,"/"],[2,[6,"statement_total_recalculation_batch"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /billing/statement_batches(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const billing_statement_batches_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"billing"],[2,[7,"/"],[2,[6,"statement_batches"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /billing/statements/:statement_id/claim_adjustments(.:format)
 * @param {any} statement_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const billing_statement_claim_adjustments_path = __jsr.r({"statement_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"billing"],[2,[7,"/"],[2,[6,"statements"],[2,[7,"/"],[2,[3,"statement_id"],[2,[7,"/"],[2,[6,"claim_adjustments"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /billing/statements/:statement_id/claim_transactions(.:format)
 * @param {any} statement_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const billing_statement_claim_transactions_path = __jsr.r({"statement_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"billing"],[2,[7,"/"],[2,[6,"statements"],[2,[7,"/"],[2,[3,"statement_id"],[2,[7,"/"],[2,[6,"claim_transactions"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /billing/statements/:statement_id/statement_adjustments(.:format)
 * @param {any} statement_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const billing_statement_statement_adjustments_path = __jsr.r({"statement_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"billing"],[2,[7,"/"],[2,[6,"statements"],[2,[7,"/"],[2,[3,"statement_id"],[2,[7,"/"],[2,[6,"statement_adjustments"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /billing/statements/:statement_id/statement_deletions(.:format)
 * @param {any} statement_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const billing_statement_statement_deletions_path = __jsr.r({"statement_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"billing"],[2,[7,"/"],[2,[6,"statements"],[2,[7,"/"],[2,[3,"statement_id"],[2,[7,"/"],[2,[6,"statement_deletions"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /billing/statements(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const billing_statements_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"billing"],[2,[7,"/"],[2,[6,"statements"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /billing_support_error_file/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const billing_support_error_file_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"billing_support_error_file"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /billing_support(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const billing_support_index_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"billing_support"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /billing_support_rate_code_checkpoints/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const billing_support_rate_code_checkpoint_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"billing_support_rate_code_checkpoints"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /billing_support_rate_code_checkpoint_blocklist(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const billing_support_rate_code_checkpoint_blocklist_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"billing_support_rate_code_checkpoint_blocklist"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /billing_support_rate_code_checkpoints(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const billing_support_rate_code_checkpoints_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"billing_support_rate_code_checkpoints"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /billing_support_void_checkpoints/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const billing_support_void_checkpoint_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"billing_support_void_checkpoints"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /billing_support_void_checkpoint_blocklist(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const billing_support_void_checkpoint_blocklist_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"billing_support_void_checkpoint_blocklist"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /billing_support_void_checkpoints(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const billing_support_void_checkpoints_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"billing_support_void_checkpoints"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /assessments/:id/cancel_update(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const cancel_update_assessment_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"assessments"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"cancel_update"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /assessment_providers/:id/cancel_update(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const cancel_update_assessment_provider_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"assessment_providers"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"cancel_update"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /harp/hcbs_care_plans/:id/cancel_update(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const cancel_update_harp_hcbs_care_plan_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"harp"],[2,[7,"/"],[2,[6,"hcbs_care_plans"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"cancel_update"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /tracking/billing_support_questionnaires/:id/cancel_void(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const cancel_void_tracking_billing_support_questionnaire_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tracking"],[2,[7,"/"],[2,[6,"billing_support_questionnaires"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"cancel_void"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /care_gap_results(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const care_gap_results_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"care_gap_results"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /care_management_agencies(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const care_management_agencies_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"care_management_agencies"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /care_management_agencies/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const care_management_agency_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"care_management_agencies"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /care_management_agencies/:care_management_agency_id/quality_dashboard(.:format)
 * @param {any} care_management_agency_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const care_management_agency_quality_dashboard_path = __jsr.r({"care_management_agency_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"care_management_agencies"],[2,[7,"/"],[2,[3,"care_management_agency_id"],[2,[7,"/"],[2,[6,"quality_dashboard"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /care_managers/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const care_manager_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"care_managers"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /care_manager_assignment_batches(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const care_manager_assignment_batches_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"care_manager_assignment_batches"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /care_manager_dashboard(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const care_manager_dashboard_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"care_manager_dashboard"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /care_managers(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const care_managers_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"care_managers"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /care_plans/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const care_plan_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"care_plans"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /care_plans/:care_plan_id/care_plan_review_requests(.:format)
 * @param {any} care_plan_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const care_plan_care_plan_review_requests_path = __jsr.r({"care_plan_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"care_plans"],[2,[7,"/"],[2,[3,"care_plan_id"],[2,[7,"/"],[2,[6,"care_plan_review_requests"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /care_plans/:care_plan_id/care_plan_reviews(.:format)
 * @param {any} care_plan_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const care_plan_care_plan_reviews_path = __jsr.r({"care_plan_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"care_plans"],[2,[7,"/"],[2,[3,"care_plan_id"],[2,[7,"/"],[2,[6,"care_plan_reviews"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /care_plan_download_files/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const care_plan_download_file_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"care_plan_download_files"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /care_plan_download_files/:care_plan_download_file_id/errors(.:format)
 * @param {any} care_plan_download_file_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const care_plan_download_file_errors_path = __jsr.r({"care_plan_download_file_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"care_plan_download_files"],[2,[7,"/"],[2,[3,"care_plan_download_file_id"],[2,[7,"/"],[2,[6,"errors"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /care_plan_download_files/:care_plan_download_file_id/records(.:format)
 * @param {any} care_plan_download_file_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const care_plan_download_file_records_path = __jsr.r({"care_plan_download_file_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"care_plan_download_files"],[2,[7,"/"],[2,[3,"care_plan_download_file_id"],[2,[7,"/"],[2,[6,"records"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /care_plan_download_files(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const care_plan_download_files_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"care_plan_download_files"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /care_plan_error_files/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const care_plan_error_file_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"care_plan_error_files"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /care_plan_reviews/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const care_plan_review_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"care_plan_reviews"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /care_plans/:care_plan_id/signed_care_plans(.:format)
 * @param {any} care_plan_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const care_plan_signed_care_plans_path = __jsr.r({"care_plan_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"care_plans"],[2,[7,"/"],[2,[3,"care_plan_id"],[2,[7,"/"],[2,[6,"signed_care_plans"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /care_plan_upload_files/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const care_plan_upload_file_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"care_plan_upload_files"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /care_plan_upload_files/:care_plan_upload_file_id/care_plan_error_files(.:format)
 * @param {any} care_plan_upload_file_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const care_plan_upload_file_care_plan_error_files_path = __jsr.r({"care_plan_upload_file_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"care_plan_upload_files"],[2,[7,"/"],[2,[3,"care_plan_upload_file_id"],[2,[7,"/"],[2,[6,"care_plan_error_files"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /care_plan_upload_files/:id/:filename(.:format)
 * @param {any} id
 * @param {any} filename
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const care_plan_upload_file_download_path = __jsr.r({"id":{"r":true},"filename":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"care_plan_upload_files"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[3,"filename"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /care_plan_upload_files(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const care_plan_upload_files_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"care_plan_upload_files"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /ces_tools/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ces_tool_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"ces_tools"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /ces_tools/:ces_tool_id/approvals(.:format)
 * @param {any} ces_tool_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ces_tool_approvals_path = __jsr.r({"ces_tool_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"ces_tools"],[2,[7,"/"],[2,[3,"ces_tool_id"],[2,[7,"/"],[2,[6,"approvals"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /ces_tools/:ces_tool_id/unapprovals(.:format)
 * @param {any} ces_tool_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ces_tool_unapprovals_path = __jsr.r({"ces_tool_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"ces_tools"],[2,[7,"/"],[2,[3,"ces_tool_id"],[2,[7,"/"],[2,[6,"unapprovals"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /charts/:chart_id(.:format)
 * @param {any} chart_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const chart_path = __jsr.r({"chart_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"charts"],[2,[7,"/"],[2,[3,"chart_id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /charts/:chart_id/assessments(.:format)
 * @param {any} chart_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const chart_assessments_path = __jsr.r({"chart_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"charts"],[2,[7,"/"],[2,[3,"chart_id"],[2,[7,"/"],[2,[6,"assessments"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /charts/:chart_id/background(.:format)
 * @param {any} chart_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const chart_background_path = __jsr.r({"chart_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"charts"],[2,[7,"/"],[2,[3,"chart_id"],[2,[7,"/"],[2,[6,"background"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /charts/:chart_id/billing(.:format)
 * @param {any} chart_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const chart_billing_index_path = __jsr.r({"chart_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"charts"],[2,[7,"/"],[2,[3,"chart_id"],[2,[7,"/"],[2,[6,"billing"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /charts/:chart_id/care_plans(.:format)
 * @param {any} chart_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const chart_care_plans_path = __jsr.r({"chart_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"charts"],[2,[7,"/"],[2,[3,"chart_id"],[2,[7,"/"],[2,[6,"care_plans"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /charts/:chart_id/childrens_hcbs_workflows(.:format)
 * @param {any} chart_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const chart_childrens_hcbs_workflows_path = __jsr.r({"chart_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"charts"],[2,[7,"/"],[2,[3,"chart_id"],[2,[7,"/"],[2,[6,"childrens_hcbs_workflows"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /charts/:chart_id/custom_assessment_responses(.:format)
 * @param {any} chart_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const chart_custom_assessment_responses_path = __jsr.r({"chart_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"charts"],[2,[7,"/"],[2,[3,"chart_id"],[2,[7,"/"],[2,[6,"custom_assessment_responses"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /billing/reports/payment_summary/chart_data(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const chart_data_billing_reports_payment_summary_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"billing"],[2,[7,"/"],[2,[6,"reports"],[2,[7,"/"],[2,[6,"payment_summary"],[2,[7,"/"],[2,[6,"chart_data"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /reports/end_date_reason_code_summary/chart_data(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const chart_data_reports_end_date_reason_code_summary_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"reports"],[2,[7,"/"],[2,[6,"end_date_reason_code_summary"],[2,[7,"/"],[2,[6,"chart_data"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /reports/segment_lengths/chart_data(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const chart_data_reports_segment_lengths_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"reports"],[2,[7,"/"],[2,[6,"segment_lengths"],[2,[7,"/"],[2,[6,"chart_data"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /reports/segment_summary_line_chart/chart_data(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const chart_data_reports_segment_summary_line_chart_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"reports"],[2,[7,"/"],[2,[6,"segment_summary_line_chart"],[2,[7,"/"],[2,[6,"chart_data"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /charts/:chart_id/documents(.:format)
 * @param {any} chart_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const chart_documents_path = __jsr.r({"chart_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"charts"],[2,[7,"/"],[2,[3,"chart_id"],[2,[7,"/"],[2,[6,"documents"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /charts/:chart_id/encounters(.:format)
 * @param {any} chart_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const chart_encounters_path = __jsr.r({"chart_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"charts"],[2,[7,"/"],[2,[3,"chart_id"],[2,[7,"/"],[2,[6,"encounters"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /charts/:chart_id/enrollment_periods(.:format)
 * @param {any} chart_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const chart_enrollment_periods_path = __jsr.r({"chart_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"charts"],[2,[7,"/"],[2,[3,"chart_id"],[2,[7,"/"],[2,[6,"enrollment_periods"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /charts/:chart_id/harp/core_services_declines(.:format)
 * @param {any} chart_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const chart_harp_core_services_declines_path = __jsr.r({"chart_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"charts"],[2,[7,"/"],[2,[3,"chart_id"],[2,[7,"/"],[2,[6,"harp"],[2,[7,"/"],[2,[6,"core_services_declines"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /charts/:chart_id/harp/core_services_interest_confirms(.:format)
 * @param {any} chart_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const chart_harp_core_services_interest_confirms_path = __jsr.r({"chart_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"charts"],[2,[7,"/"],[2,[3,"chart_id"],[2,[7,"/"],[2,[6,"harp"],[2,[7,"/"],[2,[6,"core_services_interest_confirms"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /charts/:chart_id/harp/core_services_interest_resets(.:format)
 * @param {any} chart_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const chart_harp_core_services_interest_resets_path = __jsr.r({"chart_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"charts"],[2,[7,"/"],[2,[3,"chart_id"],[2,[7,"/"],[2,[6,"harp"],[2,[7,"/"],[2,[6,"core_services_interest_resets"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /charts/:chart_id/harp/hcbs_approval_requests(.:format)
 * @param {any} chart_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const chart_harp_hcbs_approval_requests_path = __jsr.r({"chart_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"charts"],[2,[7,"/"],[2,[3,"chart_id"],[2,[7,"/"],[2,[6,"harp"],[2,[7,"/"],[2,[6,"hcbs_approval_requests"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /charts/:chart_id/harp/hcbs_approval_resets(.:format)
 * @param {any} chart_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const chart_harp_hcbs_approval_resets_path = __jsr.r({"chart_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"charts"],[2,[7,"/"],[2,[3,"chart_id"],[2,[7,"/"],[2,[6,"harp"],[2,[7,"/"],[2,[6,"hcbs_approval_resets"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /charts/:chart_id/harp/hcbs_approval_restores(.:format)
 * @param {any} chart_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const chart_harp_hcbs_approval_restores_path = __jsr.r({"chart_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"charts"],[2,[7,"/"],[2,[3,"chart_id"],[2,[7,"/"],[2,[6,"harp"],[2,[7,"/"],[2,[6,"hcbs_approval_restores"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /charts/:chart_id/harp/hcbs_approvals(.:format)
 * @param {any} chart_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const chart_harp_hcbs_approvals_path = __jsr.r({"chart_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"charts"],[2,[7,"/"],[2,[3,"chart_id"],[2,[7,"/"],[2,[6,"harp"],[2,[7,"/"],[2,[6,"hcbs_approvals"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /charts/:chart_id/harp/hcbs_assessment_declines(.:format)
 * @param {any} chart_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const chart_harp_hcbs_assessment_declines_path = __jsr.r({"chart_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"charts"],[2,[7,"/"],[2,[3,"chart_id"],[2,[7,"/"],[2,[6,"harp"],[2,[7,"/"],[2,[6,"hcbs_assessment_declines"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /charts/:chart_id/harp/hcbs_assessment_progress_activities(.:format)
 * @param {any} chart_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const chart_harp_hcbs_assessment_progress_activities_path = __jsr.r({"chart_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"charts"],[2,[7,"/"],[2,[3,"chart_id"],[2,[7,"/"],[2,[6,"harp"],[2,[7,"/"],[2,[6,"hcbs_assessment_progress_activities"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /charts/:chart_id/harp/hcbs_assessment_resets(.:format)
 * @param {any} chart_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const chart_harp_hcbs_assessment_resets_path = __jsr.r({"chart_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"charts"],[2,[7,"/"],[2,[3,"chart_id"],[2,[7,"/"],[2,[6,"harp"],[2,[7,"/"],[2,[6,"hcbs_assessment_resets"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /charts/:chart_id/harp/hcbs_assessment_restores(.:format)
 * @param {any} chart_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const chart_harp_hcbs_assessment_restores_path = __jsr.r({"chart_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"charts"],[2,[7,"/"],[2,[3,"chart_id"],[2,[7,"/"],[2,[6,"harp"],[2,[7,"/"],[2,[6,"hcbs_assessment_restores"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /charts/:chart_id/harp/hcbs_care_plan_resets(.:format)
 * @param {any} chart_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const chart_harp_hcbs_care_plan_resets_path = __jsr.r({"chart_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"charts"],[2,[7,"/"],[2,[3,"chart_id"],[2,[7,"/"],[2,[6,"harp"],[2,[7,"/"],[2,[6,"hcbs_care_plan_resets"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /charts/:chart_id/harp/hcbs_care_plan_restores(.:format)
 * @param {any} chart_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const chart_harp_hcbs_care_plan_restores_path = __jsr.r({"chart_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"charts"],[2,[7,"/"],[2,[3,"chart_id"],[2,[7,"/"],[2,[6,"harp"],[2,[7,"/"],[2,[6,"hcbs_care_plan_restores"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /charts/:chart_id/harp/hcbs_care_plan_submissions(.:format)
 * @param {any} chart_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const chart_harp_hcbs_care_plan_submissions_path = __jsr.r({"chart_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"charts"],[2,[7,"/"],[2,[3,"chart_id"],[2,[7,"/"],[2,[6,"harp"],[2,[7,"/"],[2,[6,"hcbs_care_plan_submissions"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /charts/:chart_id/harp/hcbs_care_plans(.:format)
 * @param {any} chart_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const chart_harp_hcbs_care_plans_path = __jsr.r({"chart_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"charts"],[2,[7,"/"],[2,[3,"chart_id"],[2,[7,"/"],[2,[6,"harp"],[2,[7,"/"],[2,[6,"hcbs_care_plans"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /charts/:chart_id/harp/hcbs_eligibility_assessments(.:format)
 * @param {any} chart_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const chart_harp_hcbs_eligibility_assessments_path = __jsr.r({"chart_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"charts"],[2,[7,"/"],[2,[3,"chart_id"],[2,[7,"/"],[2,[6,"harp"],[2,[7,"/"],[2,[6,"hcbs_eligibility_assessments"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /charts/:chart_id/harp/hcbs_enrollment_periods(.:format)
 * @param {any} chart_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const chart_harp_hcbs_enrollment_periods_path = __jsr.r({"chart_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"charts"],[2,[7,"/"],[2,[3,"chart_id"],[2,[7,"/"],[2,[6,"harp"],[2,[7,"/"],[2,[6,"hcbs_enrollment_periods"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /charts/:chart_id/harp/hcbs_enrollment_resets(.:format)
 * @param {any} chart_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const chart_harp_hcbs_enrollment_resets_path = __jsr.r({"chart_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"charts"],[2,[7,"/"],[2,[3,"chart_id"],[2,[7,"/"],[2,[6,"harp"],[2,[7,"/"],[2,[6,"hcbs_enrollment_resets"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /charts/:chart_id/harp/hcbs_enrollment_restores(.:format)
 * @param {any} chart_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const chart_harp_hcbs_enrollment_restores_path = __jsr.r({"chart_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"charts"],[2,[7,"/"],[2,[3,"chart_id"],[2,[7,"/"],[2,[6,"harp"],[2,[7,"/"],[2,[6,"hcbs_enrollment_restores"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /charts/:chart_id/harp/hcbs_interest_confirms(.:format)
 * @param {any} chart_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const chart_harp_hcbs_interest_confirms_path = __jsr.r({"chart_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"charts"],[2,[7,"/"],[2,[3,"chart_id"],[2,[7,"/"],[2,[6,"harp"],[2,[7,"/"],[2,[6,"hcbs_interest_confirms"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /charts/:chart_id/harp/hcbs_interest_declines(.:format)
 * @param {any} chart_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const chart_harp_hcbs_interest_declines_path = __jsr.r({"chart_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"charts"],[2,[7,"/"],[2,[3,"chart_id"],[2,[7,"/"],[2,[6,"harp"],[2,[7,"/"],[2,[6,"hcbs_interest_declines"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /charts/:chart_id/harp/hcbs_interest_resets(.:format)
 * @param {any} chart_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const chart_harp_hcbs_interest_resets_path = __jsr.r({"chart_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"charts"],[2,[7,"/"],[2,[3,"chart_id"],[2,[7,"/"],[2,[6,"harp"],[2,[7,"/"],[2,[6,"hcbs_interest_resets"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /charts/:chart_id/harp/workflow_status(.:format)
 * @param {any} chart_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const chart_harp_workflow_status_path = __jsr.r({"chart_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"charts"],[2,[7,"/"],[2,[3,"chart_id"],[2,[7,"/"],[2,[6,"harp"],[2,[7,"/"],[2,[6,"workflow_status"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /charts/:chart_id/high_fidelity_wrap/crisis_events(.:format)
 * @param {any} chart_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const chart_high_fidelity_wrap_crisis_events_path = __jsr.r({"chart_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"charts"],[2,[7,"/"],[2,[3,"chart_id"],[2,[7,"/"],[2,[6,"high_fidelity_wrap"],[2,[7,"/"],[2,[6,"crisis_events"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /charts/:chart_id/high_fidelity_wrap/crisis_safety_plans(.:format)
 * @param {any} chart_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const chart_high_fidelity_wrap_crisis_safety_plans_path = __jsr.r({"chart_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"charts"],[2,[7,"/"],[2,[3,"chart_id"],[2,[7,"/"],[2,[6,"high_fidelity_wrap"],[2,[7,"/"],[2,[6,"crisis_safety_plans"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /charts/:chart_id/high_fidelity_wrap/enrollments(.:format)
 * @param {any} chart_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const chart_high_fidelity_wrap_enrollments_path = __jsr.r({"chart_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"charts"],[2,[7,"/"],[2,[3,"chart_id"],[2,[7,"/"],[2,[6,"high_fidelity_wrap"],[2,[7,"/"],[2,[6,"enrollments"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /charts/:chart_id/high_fidelity_wrap/family_stories(.:format)
 * @param {any} chart_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const chart_high_fidelity_wrap_family_stories_path = __jsr.r({"chart_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"charts"],[2,[7,"/"],[2,[3,"chart_id"],[2,[7,"/"],[2,[6,"high_fidelity_wrap"],[2,[7,"/"],[2,[6,"family_stories"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /charts/:chart_id/mco_gaps_in_care(.:format)
 * @param {any} chart_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const chart_mco_gaps_in_care_path = __jsr.r({"chart_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"charts"],[2,[7,"/"],[2,[3,"chart_id"],[2,[7,"/"],[2,[6,"mco_gaps_in_care"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /charts/:chart_id/memberships(.:format)
 * @param {any} chart_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const chart_memberships_path = __jsr.r({"chart_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"charts"],[2,[7,"/"],[2,[3,"chart_id"],[2,[7,"/"],[2,[6,"memberships"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /charts/:chart_id/overview(.:format)
 * @param {any} chart_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const chart_overview_path = __jsr.r({"chart_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"charts"],[2,[7,"/"],[2,[3,"chart_id"],[2,[7,"/"],[2,[6,"overview"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /charts/:chart_id/patient_flag_assignments(.:format)
 * @param {any} chart_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const chart_patient_flag_assignments_path = __jsr.r({"chart_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"charts"],[2,[7,"/"],[2,[3,"chart_id"],[2,[7,"/"],[2,[6,"patient_flag_assignments"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /charts/:chart_id/pdf_assessments(.:format)
 * @param {any} chart_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const chart_pdf_assessments_path = __jsr.r({"chart_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"charts"],[2,[7,"/"],[2,[3,"chart_id"],[2,[7,"/"],[2,[6,"pdf_assessments"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /charts/:chart_id/segments(.:format)
 * @param {any} chart_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const chart_segments_path = __jsr.r({"chart_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"charts"],[2,[7,"/"],[2,[3,"chart_id"],[2,[7,"/"],[2,[6,"segments"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /charts/:chart_id/transfers(.:format)
 * @param {any} chart_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const chart_transfers_path = __jsr.r({"chart_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"charts"],[2,[7,"/"],[2,[3,"chart_id"],[2,[7,"/"],[2,[6,"transfers"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /charts/:chart_id/transitions_of_care(.:format)
 * @param {any} chart_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const chart_transitions_of_care_index_path = __jsr.r({"chart_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"charts"],[2,[7,"/"],[2,[3,"chart_id"],[2,[7,"/"],[2,[6,"transitions_of_care"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /charts/:chart_id/unite_us(.:format)
 * @param {any} chart_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const chart_unite_us_path = __jsr.r({"chart_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"charts"],[2,[7,"/"],[2,[3,"chart_id"],[2,[7,"/"],[2,[6,"unite_us"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /charts/:chart_id/unite_us/referrals(.:format)
 * @param {any} chart_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const chart_unite_us_referrals_path = __jsr.r({"chart_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"charts"],[2,[7,"/"],[2,[3,"chart_id"],[2,[7,"/"],[2,[6,"unite_us"],[2,[7,"/"],[2,[6,"referrals"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /charted_data_copies(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const charted_data_copies_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"charted_data_copies"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /charted_data_copies/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const charted_data_copy_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"charted_data_copies"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /charts(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const charts_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"charts"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /childrens_hcbs_workflows/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const childrens_hcbs_workflow_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"childrens_hcbs_workflows"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /childrens_hcbs_workflows/:childrens_hcbs_workflow_id/capacity_management(.:format)
 * @param {any} childrens_hcbs_workflow_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const childrens_hcbs_workflow_capacity_management_path = __jsr.r({"childrens_hcbs_workflow_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"childrens_hcbs_workflows"],[2,[7,"/"],[2,[3,"childrens_hcbs_workflow_id"],[2,[7,"/"],[2,[6,"capacity_management"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /childrens_hcbs_workflows/:childrens_hcbs_workflow_id/loc_eligibility_determination(.:format)
 * @param {any} childrens_hcbs_workflow_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const childrens_hcbs_workflow_loc_eligibility_determination_path = __jsr.r({"childrens_hcbs_workflow_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"childrens_hcbs_workflows"],[2,[7,"/"],[2,[3,"childrens_hcbs_workflow_id"],[2,[7,"/"],[2,[6,"loc_eligibility_determination"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /childrens_hcbs_workflows/:childrens_hcbs_workflow_id/notice_of_decision(.:format)
 * @param {any} childrens_hcbs_workflow_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const childrens_hcbs_workflow_notice_of_decision_path = __jsr.r({"childrens_hcbs_workflow_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"childrens_hcbs_workflows"],[2,[7,"/"],[2,[3,"childrens_hcbs_workflow_id"],[2,[7,"/"],[2,[6,"notice_of_decision"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /childrens_hcbs_workflows/:childrens_hcbs_workflow_id/status_updates(.:format)
 * @param {any} childrens_hcbs_workflow_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const childrens_hcbs_workflow_status_updates_path = __jsr.r({"childrens_hcbs_workflow_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"childrens_hcbs_workflows"],[2,[7,"/"],[2,[3,"childrens_hcbs_workflow_id"],[2,[7,"/"],[2,[6,"status_updates"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /claim_transactions/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const claim_transaction_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"claim_transactions"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /claim_transactions/:claim_transaction_id/mark_for_replacement(.:format)
 * @param {any} claim_transaction_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const claim_transaction_mark_for_replacement_path = __jsr.r({"claim_transaction_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"claim_transactions"],[2,[7,"/"],[2,[3,"claim_transaction_id"],[2,[7,"/"],[2,[6,"mark_for_replacement"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /clinical_alert_removes(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const clinical_alert_removes_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"clinical_alert_removes"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /clinical_events/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const clinical_event_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"clinical_events"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /clinical_events(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const clinical_events_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"clinical_events"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /cmart_v3_reports(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const cmart_v3_reports_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"cmart_v3_reports"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /configuration(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const configuration_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"configuration"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /consent_download_files/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const consent_download_file_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"consent_download_files"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /consent_download_files/:consent_download_file_id/consent_records(.:format)
 * @param {any} consent_download_file_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const consent_download_file_consent_records_path = __jsr.r({"consent_download_file_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"consent_download_files"],[2,[7,"/"],[2,[3,"consent_download_file_id"],[2,[7,"/"],[2,[6,"consent_records"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /consent_download_files/:consent_download_file_id/errors(.:format)
 * @param {any} consent_download_file_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const consent_download_file_errors_path = __jsr.r({"consent_download_file_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"consent_download_files"],[2,[7,"/"],[2,[3,"consent_download_file_id"],[2,[7,"/"],[2,[6,"errors"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /consent_download_files(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const consent_download_files_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"consent_download_files"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /consent_error_files/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const consent_error_file_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"consent_error_files"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /consent_error_files/:consent_error_file_id/cest_error_records(.:format)
 * @param {any} consent_error_file_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const consent_error_file_cest_error_records_path = __jsr.r({"consent_error_file_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"consent_error_files"],[2,[7,"/"],[2,[3,"consent_error_file_id"],[2,[7,"/"],[2,[6,"cest_error_records"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /consent_error_files/:consent_error_file_id/consent_error_records(.:format)
 * @param {any} consent_error_file_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const consent_error_file_consent_error_records_path = __jsr.r({"consent_error_file_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"consent_error_files"],[2,[7,"/"],[2,[3,"consent_error_file_id"],[2,[7,"/"],[2,[6,"consent_error_records"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /consent_upload_files/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const consent_upload_file_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"consent_upload_files"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /consent_upload_files/:consent_upload_file_id/cest_upload_records(.:format)
 * @param {any} consent_upload_file_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const consent_upload_file_cest_upload_records_path = __jsr.r({"consent_upload_file_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"consent_upload_files"],[2,[7,"/"],[2,[3,"consent_upload_file_id"],[2,[7,"/"],[2,[6,"cest_upload_records"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /consent_upload_files/:consent_upload_file_id/consent_error_files(.:format)
 * @param {any} consent_upload_file_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const consent_upload_file_consent_error_files_path = __jsr.r({"consent_upload_file_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"consent_upload_files"],[2,[7,"/"],[2,[3,"consent_upload_file_id"],[2,[7,"/"],[2,[6,"consent_error_files"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /consent_upload_files/:consent_upload_file_id/consent_upload_records(.:format)
 * @param {any} consent_upload_file_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const consent_upload_file_consent_upload_records_path = __jsr.r({"consent_upload_file_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"consent_upload_files"],[2,[7,"/"],[2,[3,"consent_upload_file_id"],[2,[7,"/"],[2,[6,"consent_upload_records"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /consent_upload_files(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const consent_upload_files_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"consent_upload_files"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /custom_assessment_responses/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const custom_assessment_response_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"custom_assessment_responses"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /custom_assessment_schemas/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const custom_assessment_schema_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"custom_assessment_schemas"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /custom_assessment_schema/versions/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const custom_assessment_schema_version_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"custom_assessment_schema"],[2,[7,"/"],[2,[6,"versions"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /custom_assessment_schema_version_duplicates(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const custom_assessment_schema_version_duplicates_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"custom_assessment_schema_version_duplicates"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /custom_assessment_schemas/:custom_assessment_schema_id/versions(.:format)
 * @param {any} custom_assessment_schema_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const custom_assessment_schema_versions_path = __jsr.r({"custom_assessment_schema_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"custom_assessment_schemas"],[2,[7,"/"],[2,[3,"custom_assessment_schema_id"],[2,[7,"/"],[2,[6,"versions"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /custom_assessment_schemas(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const custom_assessment_schemas_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"custom_assessment_schemas"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /dashboards/care_management_agency_admin(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboards_care_management_agency_admin_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"dashboards"],[2,[7,"/"],[2,[6,"care_management_agency_admin"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /dashboards/health_home_admin(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboards_health_home_admin_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"dashboards"],[2,[7,"/"],[2,[6,"health_home_admin"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /delayed_job
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const delayed_job_web_path = __jsr.r({}, [2,[7,"/"],[6,"delayed_job"]]);

/**
 * Generates rails route to
 * /assessment_providers/:id/delete_dental(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const delete_dental_assessment_provider_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"assessment_providers"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"delete_dental"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /assessment_providers/:id/delete_mental_health(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const delete_mental_health_assessment_provider_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"assessment_providers"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"delete_mental_health"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /assessment_providers/:id/delete_other(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const delete_other_assessment_provider_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"assessment_providers"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"delete_other"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /assessment_providers/:id/delete_physical_rehab(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const delete_physical_rehab_assessment_provider_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"assessment_providers"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"delete_physical_rehab"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /assessment_providers/:id/delete_podiatry(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const delete_podiatry_assessment_provider_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"assessment_providers"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"delete_podiatry"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /assessment_providers/:id/delete_prenatal(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const delete_prenatal_assessment_provider_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"assessment_providers"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"delete_prenatal"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /assessment_providers/:id/delete_primary_medical(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const delete_primary_medical_assessment_provider_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"assessment_providers"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"delete_primary_medical"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /sessions/delete(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const delete_sessions_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"sessions"],[2,[7,"/"],[2,[6,"delete"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /assessment_providers/:id/delete_substance_use(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const delete_substance_use_assessment_provider_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"assessment_providers"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"delete_substance_use"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /assessment_providers/:id/delete_vision(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const delete_vision_assessment_provider_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"assessment_providers"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"delete_vision"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /diagnoses/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const diagnosis_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"diagnoses"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /documents/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const document_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"documents"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /document_types/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const document_type_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"document_types"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /document_types(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const document_types_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"document_types"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /consent_upload_files/:id/download(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const download_consent_upload_file_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"consent_upload_files"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"download"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /patient_tracking_files/:id/download(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const download_patient_tracking_file_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"patient_tracking_files"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"download"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /tracking/billing_support_upload_files/:id/download(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const download_tracking_billing_support_upload_file_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tracking"],[2,[7,"/"],[2,[6,"billing_support_upload_files"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"download"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /assessments/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_assessment_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"assessments"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /assessment_providers/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_assessment_provider_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"assessment_providers"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /billing/admin_fees/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_billing_admin_fee_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"billing"],[2,[7,"/"],[2,[6,"admin_fees"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /billing/administrative_fee_batches/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_billing_administrative_fee_batch_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"billing"],[2,[7,"/"],[2,[6,"administrative_fee_batches"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /billing/amida_care_incentive_program_claim_batches/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_billing_amida_care_incentive_program_claim_batch_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"billing"],[2,[7,"/"],[2,[6,"amida_care_incentive_program_claim_batches"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /billing/claims/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_billing_claim_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"billing"],[2,[7,"/"],[2,[6,"claims"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /billing/claim_batches/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_billing_claim_batch_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"billing"],[2,[7,"/"],[2,[6,"claim_batches"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /billing/claim_submission_batches/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_billing_claim_submission_batch_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"billing"],[2,[7,"/"],[2,[6,"claim_submission_batches"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /billing/claim_transaction_files/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_billing_claim_transaction_file_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"billing"],[2,[7,"/"],[2,[6,"claim_transaction_files"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /billing/hcbs_eligibility_assessment_claim_batches/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_billing_hcbs_eligibility_assessment_claim_batch_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"billing"],[2,[7,"/"],[2,[6,"hcbs_eligibility_assessment_claim_batches"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /billing/statement_adjustments/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_billing_statement_adjustment_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"billing"],[2,[7,"/"],[2,[6,"statement_adjustments"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /care_management_agencies/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_care_management_agency_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"care_management_agencies"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /care_managers/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_care_manager_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"care_managers"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /care_plans/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_care_plan_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"care_plans"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /care_plan_reviews/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_care_plan_review_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"care_plan_reviews"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /ces_tools/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_ces_tool_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"ces_tools"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /charts/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_chart_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"charts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /charts/:chart_id/unite_us/edit(.:format)
 * @param {any} chart_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_chart_unite_us_path = __jsr.r({"chart_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"charts"],[2,[7,"/"],[2,[3,"chart_id"],[2,[7,"/"],[2,[6,"unite_us"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /childrens_hcbs_workflows/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_childrens_hcbs_workflow_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"childrens_hcbs_workflows"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /childrens_hcbs_workflows/:childrens_hcbs_workflow_id/capacity_management/edit(.:format)
 * @param {any} childrens_hcbs_workflow_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_childrens_hcbs_workflow_capacity_management_path = __jsr.r({"childrens_hcbs_workflow_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"childrens_hcbs_workflows"],[2,[7,"/"],[2,[3,"childrens_hcbs_workflow_id"],[2,[7,"/"],[2,[6,"capacity_management"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /childrens_hcbs_workflows/:childrens_hcbs_workflow_id/disenrollment/edit(.:format)
 * @param {any} childrens_hcbs_workflow_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_childrens_hcbs_workflow_disenrollment_path = __jsr.r({"childrens_hcbs_workflow_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"childrens_hcbs_workflows"],[2,[7,"/"],[2,[3,"childrens_hcbs_workflow_id"],[2,[7,"/"],[2,[6,"disenrollment"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /childrens_hcbs_workflows/:childrens_hcbs_workflow_id/freedom_of_choice/edit(.:format)
 * @param {any} childrens_hcbs_workflow_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_childrens_hcbs_workflow_freedom_of_choice_path = __jsr.r({"childrens_hcbs_workflow_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"childrens_hcbs_workflows"],[2,[7,"/"],[2,[3,"childrens_hcbs_workflow_id"],[2,[7,"/"],[2,[6,"freedom_of_choice"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /childrens_hcbs_workflows/:childrens_hcbs_workflow_id/loc_eligibility_determination/edit(.:format)
 * @param {any} childrens_hcbs_workflow_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_childrens_hcbs_workflow_loc_eligibility_determination_path = __jsr.r({"childrens_hcbs_workflow_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"childrens_hcbs_workflows"],[2,[7,"/"],[2,[3,"childrens_hcbs_workflow_id"],[2,[7,"/"],[2,[6,"loc_eligibility_determination"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /childrens_hcbs_workflows/:childrens_hcbs_workflow_id/notice_of_decision/edit(.:format)
 * @param {any} childrens_hcbs_workflow_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_childrens_hcbs_workflow_notice_of_decision_path = __jsr.r({"childrens_hcbs_workflow_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"childrens_hcbs_workflows"],[2,[7,"/"],[2,[3,"childrens_hcbs_workflow_id"],[2,[7,"/"],[2,[6,"notice_of_decision"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /childrens_hcbs_workflows/:childrens_hcbs_workflow_id/population_specific_form/edit(.:format)
 * @param {any} childrens_hcbs_workflow_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_childrens_hcbs_workflow_population_specific_form_path = __jsr.r({"childrens_hcbs_workflow_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"childrens_hcbs_workflows"],[2,[7,"/"],[2,[3,"childrens_hcbs_workflow_id"],[2,[7,"/"],[2,[6,"population_specific_form"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /childrens_hcbs_workflows/:childrens_hcbs_workflow_id/referral_documentation/edit(.:format)
 * @param {any} childrens_hcbs_workflow_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_childrens_hcbs_workflow_referral_documentation_path = __jsr.r({"childrens_hcbs_workflow_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"childrens_hcbs_workflows"],[2,[7,"/"],[2,[3,"childrens_hcbs_workflow_id"],[2,[7,"/"],[2,[6,"referral_documentation"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /custom_assessment_schemas/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_custom_assessment_schema_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"custom_assessment_schemas"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /custom_assessment_schema/versions/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_custom_assessment_schema_version_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"custom_assessment_schema"],[2,[7,"/"],[2,[6,"versions"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /documents/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_document_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"documents"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /document_types/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_document_type_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"document_types"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /eligibility_insurance_plans/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_eligibility_insurance_plan_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"eligibility_insurance_plans"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /encounters/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_encounter_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"encounters"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /enrollment_periods/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_enrollment_period_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"enrollment_periods"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /error_report/uploaded_files/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_error_report_uploaded_file_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"error_report"],[2,[7,"/"],[2,[6,"uploaded_files"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /harp/backup_plan_contacts/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_harp_backup_plan_contact_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"harp"],[2,[7,"/"],[2,[6,"backup_plan_contacts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /harp/behavioral_health_needs/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_harp_behavioral_health_need_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"harp"],[2,[7,"/"],[2,[6,"behavioral_health_needs"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /harp/goals/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_harp_goal_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"harp"],[2,[7,"/"],[2,[6,"goals"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /harp/hcbs_approvals/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_harp_hcbs_approval_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"harp"],[2,[7,"/"],[2,[6,"hcbs_approvals"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /harp/hcbs_care_plans/:hcbs_care_plan_id/demographics/edit(.:format)
 * @param {any} hcbs_care_plan_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_harp_hcbs_care_plan_demographics_path = __jsr.r({"hcbs_care_plan_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"harp"],[2,[7,"/"],[2,[6,"hcbs_care_plans"],[2,[7,"/"],[2,[3,"hcbs_care_plan_id"],[2,[7,"/"],[2,[6,"demographics"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /harp/hcbs_care_plans/:hcbs_care_plan_id/education_employments/edit(.:format)
 * @param {any} hcbs_care_plan_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_harp_hcbs_care_plan_education_employments_path = __jsr.r({"hcbs_care_plan_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"harp"],[2,[7,"/"],[2,[6,"hcbs_care_plans"],[2,[7,"/"],[2,[3,"hcbs_care_plan_id"],[2,[7,"/"],[2,[6,"education_employments"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /harp/hcbs_care_plans/:hcbs_care_plan_id/housing_questionnaire/edit(.:format)
 * @param {any} hcbs_care_plan_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_harp_hcbs_care_plan_housing_questionnaire_path = __jsr.r({"hcbs_care_plan_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"harp"],[2,[7,"/"],[2,[6,"hcbs_care_plans"],[2,[7,"/"],[2,[3,"hcbs_care_plan_id"],[2,[7,"/"],[2,[6,"housing_questionnaire"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /harp/hcbs_eligibility_assessments/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_harp_hcbs_eligibility_assessment_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"harp"],[2,[7,"/"],[2,[6,"hcbs_eligibility_assessments"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /harp/hcbs_enrollment_periods/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_harp_hcbs_enrollment_period_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"harp"],[2,[7,"/"],[2,[6,"hcbs_enrollment_periods"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /harp/interventions/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_harp_intervention_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"harp"],[2,[7,"/"],[2,[6,"interventions"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /harp/medical_needs/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_harp_medical_need_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"harp"],[2,[7,"/"],[2,[6,"medical_needs"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /harp/natural_disaster_contacts/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_harp_natural_disaster_contact_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"harp"],[2,[7,"/"],[2,[6,"natural_disaster_contacts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /harp/other_emergency_contacts/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_harp_other_emergency_contact_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"harp"],[2,[7,"/"],[2,[6,"other_emergency_contacts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /harp/recommended_providers_and_services/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_harp_recommended_providers_and_service_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"harp"],[2,[7,"/"],[2,[6,"recommended_providers_and_services"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /harp/services/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_harp_service_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"harp"],[2,[7,"/"],[2,[6,"services"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /harp/social_service_needs/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_harp_social_service_need_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"harp"],[2,[7,"/"],[2,[6,"social_service_needs"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /harp/support_contacts/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_harp_support_contact_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"harp"],[2,[7,"/"],[2,[6,"support_contacts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /health_homes/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_health_home_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"health_homes"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /health_home_consents/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_health_home_consent_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"health_home_consents"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /health_home_opt_outs/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_health_home_opt_out_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"health_home_opt_outs"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /high_fidelity_wrap/child_and_family_team_meetings/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_high_fidelity_wrap_child_and_family_team_meeting_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"high_fidelity_wrap"],[2,[7,"/"],[2,[6,"child_and_family_team_meetings"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /high_fidelity_wrap/crisis_events/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_high_fidelity_wrap_crisis_event_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"high_fidelity_wrap"],[2,[7,"/"],[2,[6,"crisis_events"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /high_fidelity_wrap/crisis_safety_plans/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_high_fidelity_wrap_crisis_safety_plan_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"high_fidelity_wrap"],[2,[7,"/"],[2,[6,"crisis_safety_plans"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /high_fidelity_wrap/enrollments/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_high_fidelity_wrap_enrollment_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"high_fidelity_wrap"],[2,[7,"/"],[2,[6,"enrollments"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /high_fidelity_wrap/family_stories/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_high_fidelity_wrap_family_story_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"high_fidelity_wrap"],[2,[7,"/"],[2,[6,"family_stories"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /import/care_gap_files/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_import_care_gap_file_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"import"],[2,[7,"/"],[2,[6,"care_gap_files"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /import/clinical_alert_import_files/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_import_clinical_alert_import_file_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"import"],[2,[7,"/"],[2,[6,"clinical_alert_import_files"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /import/encounter_import_files/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_import_encounter_import_file_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"import"],[2,[7,"/"],[2,[6,"encounter_import_files"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /managed_care_organizations/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_managed_care_organization_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"managed_care_organizations"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /managed_care_plans/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_managed_care_plan_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"managed_care_plans"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /password/edit(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_password_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"password"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /patient_flags/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_patient_flag_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"patient_flags"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /patient_tracking_files/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_patient_tracking_file_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"patient_tracking_files"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /pdf_assessments/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_pdf_assessment_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pdf_assessments"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /providers/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_provider_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"providers"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /reports/unlocked_encounters/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_reports_unlocked_encounter_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"reports"],[2,[7,"/"],[2,[6,"unlocked_encounters"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /segments/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_segment_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"segments"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /supervisory_teams/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_supervisory_team_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"supervisory_teams"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /tracking/billing_support_questionnaires/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_tracking_billing_support_questionnaire_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tracking"],[2,[7,"/"],[2,[6,"billing_support_questionnaires"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /tracking/billing_support_upload_files/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_tracking_billing_support_upload_file_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tracking"],[2,[7,"/"],[2,[6,"billing_support_upload_files"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /tracking/billing_support_upload_files/:billing_support_upload_file_id/billing_support_error_file/:id/edit(.:format)
 * @param {any} billing_support_upload_file_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_tracking_billing_support_upload_file_billing_support_error_file_path = __jsr.r({"billing_support_upload_file_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tracking"],[2,[7,"/"],[2,[6,"billing_support_upload_files"],[2,[7,"/"],[2,[3,"billing_support_upload_file_id"],[2,[7,"/"],[2,[6,"billing_support_error_file"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /tracking/enrollment_files/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_tracking_enrollment_file_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tracking"],[2,[7,"/"],[2,[6,"enrollment_files"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /tracking/enrollment_files/:enrollment_file_id/enrollment_records/:id/edit(.:format)
 * @param {any} enrollment_file_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_tracking_enrollment_file_enrollment_record_path = __jsr.r({"enrollment_file_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tracking"],[2,[7,"/"],[2,[6,"enrollment_files"],[2,[7,"/"],[2,[3,"enrollment_file_id"],[2,[7,"/"],[2,[6,"enrollment_records"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_user_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /eligibility_insurance_plans/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const eligibility_insurance_plan_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"eligibility_insurance_plans"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /eligibility_insurance_plans(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const eligibility_insurance_plans_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"eligibility_insurance_plans"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /otp/email(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const email_otp_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"otp"],[2,[7,"/"],[2,[6,"email"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /otp/enable(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const enable_otp_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"otp"],[2,[7,"/"],[2,[6,"enable"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /encounters/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const encounter_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"encounters"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /encounters/:encounter_id/history(.:format)
 * @param {any} encounter_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const encounter_history_index_path = __jsr.r({"encounter_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"encounters"],[2,[7,"/"],[2,[3,"encounter_id"],[2,[7,"/"],[2,[6,"history"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /encounters(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const encounters_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"encounters"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /enrollment_periods/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const enrollment_period_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"enrollment_periods"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /otp/enter(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const enter_otp_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"otp"],[2,[7,"/"],[2,[6,"enter"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /error_report/uploaded_files/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const error_report_uploaded_file_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"error_report"],[2,[7,"/"],[2,[6,"uploaded_files"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /error_report/uploaded_files(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const error_report_uploaded_files_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"error_report"],[2,[7,"/"],[2,[6,"uploaded_files"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /extracts/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const extract_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"extracts"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /extracts/:extract_id/file/:filename
 * @param {any} extract_id
 * @param {any} filename
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const extract_file_path = __jsr.r({"extract_id":{"r":true},"filename":{"r":true}}, [2,[7,"/"],[2,[6,"extracts"],[2,[7,"/"],[2,[3,"extract_id"],[2,[7,"/"],[2,[6,"file"],[2,[7,"/"],[3,"filename"]]]]]]]]);

/**
 * Generates rails route to
 * /extracts(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const extracts_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"extracts"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /file_export_downloads/:id/:filename(.:format)
 * @param {any} id
 * @param {any} filename
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const file_export_download_path = __jsr.r({"id":{"r":true},"filename":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"file_export_downloads"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[3,"filename"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /harp/backup_plan_contacts/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const harp_backup_plan_contact_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"harp"],[2,[7,"/"],[2,[6,"backup_plan_contacts"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /harp/behavioral_health_needs/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const harp_behavioral_health_need_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"harp"],[2,[7,"/"],[2,[6,"behavioral_health_needs"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /harp/goals/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const harp_goal_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"harp"],[2,[7,"/"],[2,[6,"goals"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /harp/hcbs_approvals/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const harp_hcbs_approval_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"harp"],[2,[7,"/"],[2,[6,"hcbs_approvals"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /harp/hcbs_approvals/:hcbs_approval_id/losd_file/:filename
 * @param {any} hcbs_approval_id
 * @param {any} filename
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const harp_hcbs_approval_losd_file_path = __jsr.r({"hcbs_approval_id":{"r":true},"filename":{"r":true}}, [2,[7,"/"],[2,[6,"harp"],[2,[7,"/"],[2,[6,"hcbs_approvals"],[2,[7,"/"],[2,[3,"hcbs_approval_id"],[2,[7,"/"],[2,[6,"losd_file"],[2,[7,"/"],[3,"filename"]]]]]]]]]]);

/**
 * Generates rails route to
 * /harp/hcbs_care_plans/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const harp_hcbs_care_plan_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"harp"],[2,[7,"/"],[2,[6,"hcbs_care_plans"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /harp/hcbs_care_plans/:hcbs_care_plan_id/backup_plan_contacts(.:format)
 * @param {any} hcbs_care_plan_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const harp_hcbs_care_plan_backup_plan_contacts_path = __jsr.r({"hcbs_care_plan_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"harp"],[2,[7,"/"],[2,[6,"hcbs_care_plans"],[2,[7,"/"],[2,[3,"hcbs_care_plan_id"],[2,[7,"/"],[2,[6,"backup_plan_contacts"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /harp/hcbs_care_plans/:hcbs_care_plan_id/behavioral_health_needs(.:format)
 * @param {any} hcbs_care_plan_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const harp_hcbs_care_plan_behavioral_health_needs_path = __jsr.r({"hcbs_care_plan_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"harp"],[2,[7,"/"],[2,[6,"hcbs_care_plans"],[2,[7,"/"],[2,[3,"hcbs_care_plan_id"],[2,[7,"/"],[2,[6,"behavioral_health_needs"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /harp/hcbs_care_plans/:hcbs_care_plan_id/demographics(.:format)
 * @param {any} hcbs_care_plan_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const harp_hcbs_care_plan_demographics_path = __jsr.r({"hcbs_care_plan_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"harp"],[2,[7,"/"],[2,[6,"hcbs_care_plans"],[2,[7,"/"],[2,[3,"hcbs_care_plan_id"],[2,[7,"/"],[2,[6,"demographics"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /harp/hcbs_care_plans/:hcbs_care_plan_id/education_employments(.:format)
 * @param {any} hcbs_care_plan_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const harp_hcbs_care_plan_education_employments_path = __jsr.r({"hcbs_care_plan_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"harp"],[2,[7,"/"],[2,[6,"hcbs_care_plans"],[2,[7,"/"],[2,[3,"hcbs_care_plan_id"],[2,[7,"/"],[2,[6,"education_employments"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /harp/hcbs_care_plans/:hcbs_care_plan_id/goals(.:format)
 * @param {any} hcbs_care_plan_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const harp_hcbs_care_plan_goals_path = __jsr.r({"hcbs_care_plan_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"harp"],[2,[7,"/"],[2,[6,"hcbs_care_plans"],[2,[7,"/"],[2,[3,"hcbs_care_plan_id"],[2,[7,"/"],[2,[6,"goals"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /harp/hcbs_care_plans/:hcbs_care_plan_id/housing_questionnaire(.:format)
 * @param {any} hcbs_care_plan_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const harp_hcbs_care_plan_housing_questionnaire_path = __jsr.r({"hcbs_care_plan_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"harp"],[2,[7,"/"],[2,[6,"hcbs_care_plans"],[2,[7,"/"],[2,[3,"hcbs_care_plan_id"],[2,[7,"/"],[2,[6,"housing_questionnaire"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /harp/hcbs_care_plans/:hcbs_care_plan_id/interventions(.:format)
 * @param {any} hcbs_care_plan_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const harp_hcbs_care_plan_interventions_path = __jsr.r({"hcbs_care_plan_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"harp"],[2,[7,"/"],[2,[6,"hcbs_care_plans"],[2,[7,"/"],[2,[3,"hcbs_care_plan_id"],[2,[7,"/"],[2,[6,"interventions"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /harp/hcbs_care_plans/:hcbs_care_plan_id/medical_needs(.:format)
 * @param {any} hcbs_care_plan_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const harp_hcbs_care_plan_medical_needs_path = __jsr.r({"hcbs_care_plan_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"harp"],[2,[7,"/"],[2,[6,"hcbs_care_plans"],[2,[7,"/"],[2,[3,"hcbs_care_plan_id"],[2,[7,"/"],[2,[6,"medical_needs"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /harp/hcbs_care_plans/:hcbs_care_plan_id/natural_disaster_contacts(.:format)
 * @param {any} hcbs_care_plan_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const harp_hcbs_care_plan_natural_disaster_contacts_path = __jsr.r({"hcbs_care_plan_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"harp"],[2,[7,"/"],[2,[6,"hcbs_care_plans"],[2,[7,"/"],[2,[3,"hcbs_care_plan_id"],[2,[7,"/"],[2,[6,"natural_disaster_contacts"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /harp/hcbs_care_plans/:hcbs_care_plan_id/other_emergency_contacts(.:format)
 * @param {any} hcbs_care_plan_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const harp_hcbs_care_plan_other_emergency_contacts_path = __jsr.r({"hcbs_care_plan_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"harp"],[2,[7,"/"],[2,[6,"hcbs_care_plans"],[2,[7,"/"],[2,[3,"hcbs_care_plan_id"],[2,[7,"/"],[2,[6,"other_emergency_contacts"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /harp/hcbs_care_plans/:hcbs_care_plan_id/recommended_providers_and_services(.:format)
 * @param {any} hcbs_care_plan_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const harp_hcbs_care_plan_recommended_providers_and_services_path = __jsr.r({"hcbs_care_plan_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"harp"],[2,[7,"/"],[2,[6,"hcbs_care_plans"],[2,[7,"/"],[2,[3,"hcbs_care_plan_id"],[2,[7,"/"],[2,[6,"recommended_providers_and_services"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /harp/hcbs_care_plans/:hcbs_care_plan_id/services(.:format)
 * @param {any} hcbs_care_plan_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const harp_hcbs_care_plan_services_path = __jsr.r({"hcbs_care_plan_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"harp"],[2,[7,"/"],[2,[6,"hcbs_care_plans"],[2,[7,"/"],[2,[3,"hcbs_care_plan_id"],[2,[7,"/"],[2,[6,"services"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /harp/hcbs_care_plans/:hcbs_care_plan_id/signed_care_plans(.:format)
 * @param {any} hcbs_care_plan_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const harp_hcbs_care_plan_signed_care_plans_path = __jsr.r({"hcbs_care_plan_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"harp"],[2,[7,"/"],[2,[6,"hcbs_care_plans"],[2,[7,"/"],[2,[3,"hcbs_care_plan_id"],[2,[7,"/"],[2,[6,"signed_care_plans"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /harp/hcbs_care_plans/:hcbs_care_plan_id/social_service_needs(.:format)
 * @param {any} hcbs_care_plan_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const harp_hcbs_care_plan_social_service_needs_path = __jsr.r({"hcbs_care_plan_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"harp"],[2,[7,"/"],[2,[6,"hcbs_care_plans"],[2,[7,"/"],[2,[3,"hcbs_care_plan_id"],[2,[7,"/"],[2,[6,"social_service_needs"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /harp/hcbs_care_plans/:hcbs_care_plan_id/support_contacts(.:format)
 * @param {any} hcbs_care_plan_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const harp_hcbs_care_plan_support_contacts_path = __jsr.r({"hcbs_care_plan_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"harp"],[2,[7,"/"],[2,[6,"hcbs_care_plans"],[2,[7,"/"],[2,[3,"hcbs_care_plan_id"],[2,[7,"/"],[2,[6,"support_contacts"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /harp/hcbs_eligibility_assessments/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const harp_hcbs_eligibility_assessment_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"harp"],[2,[7,"/"],[2,[6,"hcbs_eligibility_assessments"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /harp/hcbs_eligibility_assessments/:hcbs_eligibility_assessment_id/documents/:id/:filename
 * @param {any} hcbs_eligibility_assessment_id
 * @param {any} id
 * @param {any} filename
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const harp_hcbs_eligibility_assessment_document_path = __jsr.r({"hcbs_eligibility_assessment_id":{"r":true},"id":{"r":true},"filename":{"r":true}}, [2,[7,"/"],[2,[6,"harp"],[2,[7,"/"],[2,[6,"hcbs_eligibility_assessments"],[2,[7,"/"],[2,[3,"hcbs_eligibility_assessment_id"],[2,[7,"/"],[2,[6,"documents"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[3,"filename"]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /harp/hcbs_enrollment_periods/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const harp_hcbs_enrollment_period_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"harp"],[2,[7,"/"],[2,[6,"hcbs_enrollment_periods"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /harp/interventions/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const harp_intervention_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"harp"],[2,[7,"/"],[2,[6,"interventions"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /harp/medical_needs/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const harp_medical_need_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"harp"],[2,[7,"/"],[2,[6,"medical_needs"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /harp/natural_disaster_contacts/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const harp_natural_disaster_contact_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"harp"],[2,[7,"/"],[2,[6,"natural_disaster_contacts"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /harp/other_emergency_contacts/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const harp_other_emergency_contact_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"harp"],[2,[7,"/"],[2,[6,"other_emergency_contacts"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /harp/recommended_providers_and_services/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const harp_recommended_providers_and_service_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"harp"],[2,[7,"/"],[2,[6,"recommended_providers_and_services"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /harp/services/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const harp_service_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"harp"],[2,[7,"/"],[2,[6,"services"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /harp/signed_care_plans/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const harp_signed_care_plan_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"harp"],[2,[7,"/"],[2,[6,"signed_care_plans"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /harp/social_service_needs/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const harp_social_service_need_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"harp"],[2,[7,"/"],[2,[6,"social_service_needs"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /harp/support_contacts/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const harp_support_contact_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"harp"],[2,[7,"/"],[2,[6,"support_contacts"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /health_homes/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const health_home_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"health_homes"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /health_homes/:health_home_id/care_suggestions(.:format)
 * @param {any} health_home_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const health_home_care_suggestions_path = __jsr.r({"health_home_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"health_homes"],[2,[7,"/"],[2,[3,"health_home_id"],[2,[7,"/"],[2,[6,"care_suggestions"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /health_home_consents/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const health_home_consent_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"health_home_consents"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /health_homes/:health_home_id/health_home_aging_payer_report(.:format)
 * @param {any} health_home_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const health_home_health_home_aging_payer_report_path = __jsr.r({"health_home_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"health_homes"],[2,[7,"/"],[2,[3,"health_home_id"],[2,[7,"/"],[2,[6,"health_home_aging_payer_report"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /health_home_opt_outs/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const health_home_opt_out_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"health_home_opt_outs"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /health_homes/:health_home_id/quality_dashboard(.:format)
 * @param {any} health_home_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const health_home_quality_dashboard_path = __jsr.r({"health_home_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"health_homes"],[2,[7,"/"],[2,[3,"health_home_id"],[2,[7,"/"],[2,[6,"quality_dashboard"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /health_homes(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const health_homes_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"health_homes"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /high_fidelity_wrap/child_and_family_team_meetings/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const high_fidelity_wrap_child_and_family_team_meeting_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"high_fidelity_wrap"],[2,[7,"/"],[2,[6,"child_and_family_team_meetings"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /high_fidelity_wrap/crisis_events/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const high_fidelity_wrap_crisis_event_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"high_fidelity_wrap"],[2,[7,"/"],[2,[6,"crisis_events"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /high_fidelity_wrap/crisis_safety_plans/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const high_fidelity_wrap_crisis_safety_plan_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"high_fidelity_wrap"],[2,[7,"/"],[2,[6,"crisis_safety_plans"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /high_fidelity_wrap/enrollments/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const high_fidelity_wrap_enrollment_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"high_fidelity_wrap"],[2,[7,"/"],[2,[6,"enrollments"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /high_fidelity_wrap/family_stories/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const high_fidelity_wrap_family_story_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"high_fidelity_wrap"],[2,[7,"/"],[2,[6,"family_stories"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /patients/:id/history(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const history_patient_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"patients"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"history"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /impersonations(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const impersonations_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"impersonations"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /import(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const import_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"import"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /import/btq_activity_files/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const import_btq_activity_file_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"import"],[2,[7,"/"],[2,[6,"btq_activity_files"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /import/btq_activity_files/:btq_activity_file_id/errors(.:format)
 * @param {any} btq_activity_file_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const import_btq_activity_file_errors_path = __jsr.r({"btq_activity_file_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"import"],[2,[7,"/"],[2,[6,"btq_activity_files"],[2,[7,"/"],[2,[3,"btq_activity_file_id"],[2,[7,"/"],[2,[6,"errors"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /import/btq_activity_files/:btq_activity_file_id/records(.:format)
 * @param {any} btq_activity_file_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const import_btq_activity_file_records_path = __jsr.r({"btq_activity_file_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"import"],[2,[7,"/"],[2,[6,"btq_activity_files"],[2,[7,"/"],[2,[3,"btq_activity_file_id"],[2,[7,"/"],[2,[6,"records"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /import/btq_activity_files(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const import_btq_activity_files_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"import"],[2,[7,"/"],[2,[6,"btq_activity_files"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /import/care_gap_files/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const import_care_gap_file_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"import"],[2,[7,"/"],[2,[6,"care_gap_files"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /import/care_gap_files/:care_gap_file_id/errors(.:format)
 * @param {any} care_gap_file_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const import_care_gap_file_errors_path = __jsr.r({"care_gap_file_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"import"],[2,[7,"/"],[2,[6,"care_gap_files"],[2,[7,"/"],[2,[3,"care_gap_file_id"],[2,[7,"/"],[2,[6,"errors"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /import/care_gap_files(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const import_care_gap_files_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"import"],[2,[7,"/"],[2,[6,"care_gap_files"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /import/clinical_alert_import_files/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const import_clinical_alert_import_file_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"import"],[2,[7,"/"],[2,[6,"clinical_alert_import_files"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /import/clinical_alert_import_files/:clinical_alert_import_file_id/errors(.:format)
 * @param {any} clinical_alert_import_file_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const import_clinical_alert_import_file_errors_path = __jsr.r({"clinical_alert_import_file_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"import"],[2,[7,"/"],[2,[6,"clinical_alert_import_files"],[2,[7,"/"],[2,[3,"clinical_alert_import_file_id"],[2,[7,"/"],[2,[6,"errors"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /import/clinical_alert_import_files/:clinical_alert_import_file_id/records(.:format)
 * @param {any} clinical_alert_import_file_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const import_clinical_alert_import_file_records_path = __jsr.r({"clinical_alert_import_file_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"import"],[2,[7,"/"],[2,[6,"clinical_alert_import_files"],[2,[7,"/"],[2,[3,"clinical_alert_import_file_id"],[2,[7,"/"],[2,[6,"records"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /import/clinical_alert_import_files(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const import_clinical_alert_import_files_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"import"],[2,[7,"/"],[2,[6,"clinical_alert_import_files"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /import/encounter_import_files/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const import_encounter_import_file_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"import"],[2,[7,"/"],[2,[6,"encounter_import_files"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /import/encounter_import_files/:encounter_import_file_id/errors(.:format)
 * @param {any} encounter_import_file_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const import_encounter_import_file_errors_path = __jsr.r({"encounter_import_file_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"import"],[2,[7,"/"],[2,[6,"encounter_import_files"],[2,[7,"/"],[2,[3,"encounter_import_file_id"],[2,[7,"/"],[2,[6,"errors"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /import/encounter_import_files/:encounter_import_file_id/records(.:format)
 * @param {any} encounter_import_file_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const import_encounter_import_file_records_path = __jsr.r({"encounter_import_file_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"import"],[2,[7,"/"],[2,[6,"encounter_import_files"],[2,[7,"/"],[2,[3,"encounter_import_file_id"],[2,[7,"/"],[2,[6,"records"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /import/encounter_import_files(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const import_encounter_import_files_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"import"],[2,[7,"/"],[2,[6,"encounter_import_files"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /tracking/billing_support_questionnaires/:id/import(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const import_tracking_billing_support_questionnaire_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tracking"],[2,[7,"/"],[2,[6,"billing_support_questionnaires"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"import"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /care_plans/:id/info(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const info_care_plan_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"care_plans"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"info"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /sessions/keepalive(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const keepalive_sessions_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"sessions"],[2,[7,"/"],[2,[6,"keepalive"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /encounters/:id/lock(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const lock_encounter_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"encounters"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"lock"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /managed_care_organizations/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const managed_care_organization_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"managed_care_organizations"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /managed_care_organizations(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const managed_care_organizations_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"managed_care_organizations"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /managed_care_plans/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const managed_care_plan_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"managed_care_plans"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /managed_care_plans(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const managed_care_plans_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"managed_care_plans"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /mco_gaps_in_care/:mco_gap_in_care_id/care_manager_status_change(.:format)
 * @param {any} mco_gap_in_care_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const mco_gap_in_care_care_manager_status_change_path = __jsr.r({"mco_gap_in_care_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"mco_gaps_in_care"],[2,[7,"/"],[2,[3,"mco_gap_in_care_id"],[2,[7,"/"],[2,[6,"care_manager_status_change"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /medications/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const medication_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"medications"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/charts/:chart_id/charted_data_copies/new(.:format)
 * @param {any} chart_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_api_v1_chart_charted_data_copy_path = __jsr.r({"chart_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"charts"],[2,[7,"/"],[2,[3,"chart_id"],[2,[7,"/"],[2,[6,"charted_data_copies"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /assessment_download_files/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_assessment_download_file_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"assessment_download_files"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /sessions/new_authenticity_token(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_authenticity_token_sessions_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"sessions"],[2,[7,"/"],[2,[6,"new_authenticity_token"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /billing/admin_fees/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_billing_admin_fee_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"billing"],[2,[7,"/"],[2,[6,"admin_fees"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /billing/administrative_fee_batches/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_billing_administrative_fee_batch_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"billing"],[2,[7,"/"],[2,[6,"administrative_fee_batches"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /billing/amida_care_incentive_program_claim_batches/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_billing_amida_care_incentive_program_claim_batch_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"billing"],[2,[7,"/"],[2,[6,"amida_care_incentive_program_claim_batches"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /billing/bulk_claim_write_off/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_billing_bulk_claim_write_off_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"billing"],[2,[7,"/"],[2,[6,"bulk_claim_write_off"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /billing/claims/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_billing_claim_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"billing"],[2,[7,"/"],[2,[6,"claims"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /billing/claim_batches/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_billing_claim_batch_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"billing"],[2,[7,"/"],[2,[6,"claim_batches"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /billing/claim_submission_batches/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_billing_claim_submission_batch_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"billing"],[2,[7,"/"],[2,[6,"claim_submission_batches"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /billing/claim_transaction_files/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_billing_claim_transaction_file_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"billing"],[2,[7,"/"],[2,[6,"claim_transaction_files"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /billing/claims/:claim_id/write_off/new(.:format)
 * @param {any} claim_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_billing_claim_write_off_path = __jsr.r({"claim_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"billing"],[2,[7,"/"],[2,[6,"claims"],[2,[7,"/"],[2,[3,"claim_id"],[2,[7,"/"],[2,[6,"write_off"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /billing/hcbs_eligibility_assessment_claim_batches/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_billing_hcbs_eligibility_assessment_claim_batch_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"billing"],[2,[7,"/"],[2,[6,"hcbs_eligibility_assessment_claim_batches"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /billing/statements/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_billing_statement_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"billing"],[2,[7,"/"],[2,[6,"statements"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /billing/statement_batches/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_billing_statement_batch_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"billing"],[2,[7,"/"],[2,[6,"statement_batches"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /billing/statement_batches/:statement_batch_id/statement_email_batch/new(.:format)
 * @param {any} statement_batch_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_billing_statement_batch_statement_email_batch_path = __jsr.r({"statement_batch_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"billing"],[2,[7,"/"],[2,[6,"statement_batches"],[2,[7,"/"],[2,[3,"statement_batch_id"],[2,[7,"/"],[2,[6,"statement_email_batch"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /billing/statement_batches/:statement_batch_id/statement_total_recalculation_batch/new(.:format)
 * @param {any} statement_batch_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_billing_statement_batch_statement_total_recalculation_batch_path = __jsr.r({"statement_batch_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"billing"],[2,[7,"/"],[2,[6,"statement_batches"],[2,[7,"/"],[2,[3,"statement_batch_id"],[2,[7,"/"],[2,[6,"statement_total_recalculation_batch"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /billing/statements/:statement_id/statement_adjustments/new(.:format)
 * @param {any} statement_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_billing_statement_statement_adjustment_path = __jsr.r({"statement_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"billing"],[2,[7,"/"],[2,[6,"statements"],[2,[7,"/"],[2,[3,"statement_id"],[2,[7,"/"],[2,[6,"statement_adjustments"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /billing_support_rate_code_checkpoints/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_billing_support_rate_code_checkpoint_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"billing_support_rate_code_checkpoints"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /billing_support_void_checkpoints/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_billing_support_void_checkpoint_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"billing_support_void_checkpoints"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /care_management_agencies/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_care_management_agency_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"care_management_agencies"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /care_managers/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_care_manager_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"care_managers"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /care_manager_assignment_batches/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_care_manager_assignment_batch_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"care_manager_assignment_batches"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /care_plans/:care_plan_id/care_plan_reviews/new(.:format)
 * @param {any} care_plan_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_care_plan_care_plan_review_path = __jsr.r({"care_plan_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"care_plans"],[2,[7,"/"],[2,[3,"care_plan_id"],[2,[7,"/"],[2,[6,"care_plan_reviews"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /care_plans/:care_plan_id/care_plan_signatures/new(.:format)
 * @param {any} care_plan_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_care_plan_care_plan_signature_path = __jsr.r({"care_plan_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"care_plans"],[2,[7,"/"],[2,[3,"care_plan_id"],[2,[7,"/"],[2,[6,"care_plan_signatures"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /care_plan_download_files/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_care_plan_download_file_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"care_plan_download_files"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /care_plans/:care_plan_id/signed_care_plans/new(.:format)
 * @param {any} care_plan_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_care_plan_signed_care_plan_path = __jsr.r({"care_plan_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"care_plans"],[2,[7,"/"],[2,[3,"care_plan_id"],[2,[7,"/"],[2,[6,"signed_care_plans"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /care_plan_upload_files/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_care_plan_upload_file_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"care_plan_upload_files"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /care_plan_upload_files/:care_plan_upload_file_id/care_plan_error_files/new(.:format)
 * @param {any} care_plan_upload_file_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_care_plan_upload_file_care_plan_error_file_path = __jsr.r({"care_plan_upload_file_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"care_plan_upload_files"],[2,[7,"/"],[2,[3,"care_plan_upload_file_id"],[2,[7,"/"],[2,[6,"care_plan_error_files"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /charts/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_chart_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"charts"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /charts/:chart_id/assessments/new(.:format)
 * @param {any} chart_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_chart_assessment_path = __jsr.r({"chart_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"charts"],[2,[7,"/"],[2,[3,"chart_id"],[2,[7,"/"],[2,[6,"assessments"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /charts/:chart_id/care_plans/new(.:format)
 * @param {any} chart_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_chart_care_plan_path = __jsr.r({"chart_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"charts"],[2,[7,"/"],[2,[3,"chart_id"],[2,[7,"/"],[2,[6,"care_plans"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /charts/:chart_id/ces_tools/new(.:format)
 * @param {any} chart_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_chart_ces_tool_path = __jsr.r({"chart_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"charts"],[2,[7,"/"],[2,[3,"chart_id"],[2,[7,"/"],[2,[6,"ces_tools"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /charts/:chart_id/childrens_hcbs_workflows/new(.:format)
 * @param {any} chart_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_chart_childrens_hcbs_workflow_path = __jsr.r({"chart_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"charts"],[2,[7,"/"],[2,[3,"chart_id"],[2,[7,"/"],[2,[6,"childrens_hcbs_workflows"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /charts/:chart_id/documents/new(.:format)
 * @param {any} chart_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_chart_document_path = __jsr.r({"chart_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"charts"],[2,[7,"/"],[2,[3,"chart_id"],[2,[7,"/"],[2,[6,"documents"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /charts/:chart_id/doh5055_forms/new(.:format)
 * @param {any} chart_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_chart_doh5055_form_path = __jsr.r({"chart_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"charts"],[2,[7,"/"],[2,[3,"chart_id"],[2,[7,"/"],[2,[6,"doh5055_forms"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /charts/:chart_id/encounters/new(.:format)
 * @param {any} chart_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_chart_encounter_path = __jsr.r({"chart_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"charts"],[2,[7,"/"],[2,[3,"chart_id"],[2,[7,"/"],[2,[6,"encounters"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /charts/:chart_id/enrollment_periods/new(.:format)
 * @param {any} chart_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_chart_enrollment_period_path = __jsr.r({"chart_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"charts"],[2,[7,"/"],[2,[3,"chart_id"],[2,[7,"/"],[2,[6,"enrollment_periods"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /charts/:chart_id/harp/hcbs_approvals/new(.:format)
 * @param {any} chart_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_chart_harp_hcbs_approval_path = __jsr.r({"chart_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"charts"],[2,[7,"/"],[2,[3,"chart_id"],[2,[7,"/"],[2,[6,"harp"],[2,[7,"/"],[2,[6,"hcbs_approvals"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /charts/:chart_id/harp/hcbs_eligibility_assessments/new(.:format)
 * @param {any} chart_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_chart_harp_hcbs_eligibility_assessment_path = __jsr.r({"chart_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"charts"],[2,[7,"/"],[2,[3,"chart_id"],[2,[7,"/"],[2,[6,"harp"],[2,[7,"/"],[2,[6,"hcbs_eligibility_assessments"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /charts/:chart_id/harp/hcbs_enrollment_periods/new(.:format)
 * @param {any} chart_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_chart_harp_hcbs_enrollment_period_path = __jsr.r({"chart_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"charts"],[2,[7,"/"],[2,[3,"chart_id"],[2,[7,"/"],[2,[6,"harp"],[2,[7,"/"],[2,[6,"hcbs_enrollment_periods"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /charts/:chart_id/health_home_consents/new(.:format)
 * @param {any} chart_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_chart_health_home_consent_path = __jsr.r({"chart_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"charts"],[2,[7,"/"],[2,[3,"chart_id"],[2,[7,"/"],[2,[6,"health_home_consents"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /charts/:chart_id/health_home_opt_outs/new(.:format)
 * @param {any} chart_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_chart_health_home_opt_out_path = __jsr.r({"chart_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"charts"],[2,[7,"/"],[2,[3,"chart_id"],[2,[7,"/"],[2,[6,"health_home_opt_outs"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /charts/:chart_id/high_fidelity_wrap/child_and_family_team_meetings/new(.:format)
 * @param {any} chart_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_chart_high_fidelity_wrap_child_and_family_team_meeting_path = __jsr.r({"chart_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"charts"],[2,[7,"/"],[2,[3,"chart_id"],[2,[7,"/"],[2,[6,"high_fidelity_wrap"],[2,[7,"/"],[2,[6,"child_and_family_team_meetings"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /charts/:chart_id/high_fidelity_wrap/crisis_events/new(.:format)
 * @param {any} chart_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_chart_high_fidelity_wrap_crisis_event_path = __jsr.r({"chart_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"charts"],[2,[7,"/"],[2,[3,"chart_id"],[2,[7,"/"],[2,[6,"high_fidelity_wrap"],[2,[7,"/"],[2,[6,"crisis_events"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /charts/:chart_id/high_fidelity_wrap/crisis_safety_plans/new(.:format)
 * @param {any} chart_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_chart_high_fidelity_wrap_crisis_safety_plan_path = __jsr.r({"chart_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"charts"],[2,[7,"/"],[2,[3,"chart_id"],[2,[7,"/"],[2,[6,"high_fidelity_wrap"],[2,[7,"/"],[2,[6,"crisis_safety_plans"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /charts/:chart_id/high_fidelity_wrap/enrollments/new(.:format)
 * @param {any} chart_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_chart_high_fidelity_wrap_enrollment_path = __jsr.r({"chart_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"charts"],[2,[7,"/"],[2,[3,"chart_id"],[2,[7,"/"],[2,[6,"high_fidelity_wrap"],[2,[7,"/"],[2,[6,"enrollments"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /charts/:chart_id/high_fidelity_wrap/family_stories/new(.:format)
 * @param {any} chart_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_chart_high_fidelity_wrap_family_story_path = __jsr.r({"chart_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"charts"],[2,[7,"/"],[2,[3,"chart_id"],[2,[7,"/"],[2,[6,"high_fidelity_wrap"],[2,[7,"/"],[2,[6,"family_stories"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /charts/:chart_id/patient_flag_assignments/new(.:format)
 * @param {any} chart_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_chart_patient_flag_assignment_path = __jsr.r({"chart_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"charts"],[2,[7,"/"],[2,[3,"chart_id"],[2,[7,"/"],[2,[6,"patient_flag_assignments"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /charts/:chart_id/pdf_assessments/new(.:format)
 * @param {any} chart_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_chart_pdf_assessment_path = __jsr.r({"chart_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"charts"],[2,[7,"/"],[2,[3,"chart_id"],[2,[7,"/"],[2,[6,"pdf_assessments"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /charts/:chart_id/segments/new(.:format)
 * @param {any} chart_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_chart_segment_path = __jsr.r({"chart_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"charts"],[2,[7,"/"],[2,[3,"chart_id"],[2,[7,"/"],[2,[6,"segments"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /charts/:chart_id/transfers/new(.:format)
 * @param {any} chart_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_chart_transfer_path = __jsr.r({"chart_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"charts"],[2,[7,"/"],[2,[3,"chart_id"],[2,[7,"/"],[2,[6,"transfers"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /charts/:chart_id/unite_us/new(.:format)
 * @param {any} chart_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_chart_unite_us_path = __jsr.r({"chart_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"charts"],[2,[7,"/"],[2,[3,"chart_id"],[2,[7,"/"],[2,[6,"unite_us"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /charted_data_copies/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_charted_data_copy_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"charted_data_copies"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /cmart_v3_reports/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_cmart_v3_report_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"cmart_v3_reports"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /consent_download_files/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_consent_download_file_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"consent_download_files"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /consent_upload_files/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_consent_upload_file_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"consent_upload_files"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /consent_upload_files/:consent_upload_file_id/consent_error_files/new(.:format)
 * @param {any} consent_upload_file_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_consent_upload_file_consent_error_file_path = __jsr.r({"consent_upload_file_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"consent_upload_files"],[2,[7,"/"],[2,[3,"consent_upload_file_id"],[2,[7,"/"],[2,[6,"consent_error_files"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /custom_assessment_schemas/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_custom_assessment_schema_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"custom_assessment_schemas"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /custom_assessment_schemas/:custom_assessment_schema_id/versions/new(.:format)
 * @param {any} custom_assessment_schema_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_custom_assessment_schema_version_path = __jsr.r({"custom_assessment_schema_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"custom_assessment_schemas"],[2,[7,"/"],[2,[3,"custom_assessment_schema_id"],[2,[7,"/"],[2,[6,"versions"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /document_types/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_document_type_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"document_types"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /eligibility_insurance_plans/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_eligibility_insurance_plan_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"eligibility_insurance_plans"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /error_report/uploaded_files/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_error_report_uploaded_file_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"error_report"],[2,[7,"/"],[2,[6,"uploaded_files"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /extracts/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_extract_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"extracts"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /harp/hcbs_care_plans/:hcbs_care_plan_id/housing_questionnaire/new(.:format)
 * @param {any} hcbs_care_plan_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_harp_hcbs_care_plan_housing_questionnaire_path = __jsr.r({"hcbs_care_plan_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"harp"],[2,[7,"/"],[2,[6,"hcbs_care_plans"],[2,[7,"/"],[2,[3,"hcbs_care_plan_id"],[2,[7,"/"],[2,[6,"housing_questionnaire"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /harp/hcbs_care_plans/:hcbs_care_plan_id/signed_care_plans/new(.:format)
 * @param {any} hcbs_care_plan_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_harp_hcbs_care_plan_signed_care_plan_path = __jsr.r({"hcbs_care_plan_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"harp"],[2,[7,"/"],[2,[6,"hcbs_care_plans"],[2,[7,"/"],[2,[3,"hcbs_care_plan_id"],[2,[7,"/"],[2,[6,"signed_care_plans"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /health_homes/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_health_home_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"health_homes"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /import/btq_activity_files/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_import_btq_activity_file_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"import"],[2,[7,"/"],[2,[6,"btq_activity_files"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /import/care_gap_files/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_import_care_gap_file_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"import"],[2,[7,"/"],[2,[6,"care_gap_files"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /import/clinical_alert_import_files/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_import_clinical_alert_import_file_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"import"],[2,[7,"/"],[2,[6,"clinical_alert_import_files"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /import/encounter_import_files/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_import_encounter_import_file_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"import"],[2,[7,"/"],[2,[6,"encounter_import_files"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /managed_care_organizations/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_managed_care_organization_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"managed_care_organizations"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /managed_care_plans/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_managed_care_plan_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"managed_care_plans"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /password_resets/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_password_reset_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"password_resets"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /patients/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_patient_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"patients"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /patients/:patient_id/assessment_providers/new(.:format)
 * @param {any} patient_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_patient_assessment_provider_path = __jsr.r({"patient_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"patients"],[2,[7,"/"],[2,[3,"patient_id"],[2,[7,"/"],[2,[6,"assessment_providers"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /patient_flags/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_patient_flag_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"patient_flags"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /patient_flag_assignment_batches/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_patient_flag_assignment_batch_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"patient_flag_assignment_batches"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /patient_tracking_files/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_patient_tracking_file_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"patient_tracking_files"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /providers/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_provider_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"providers"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /reports/unlocked_encounters/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_reports_unlocked_encounter_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"reports"],[2,[7,"/"],[2,[6,"unlocked_encounters"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /segments/:segment_id/pend/new(.:format)
 * @param {any} segment_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_segment_pend_path = __jsr.r({"segment_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"segments"],[2,[7,"/"],[2,[3,"segment_id"],[2,[7,"/"],[2,[6,"pend"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /sessions/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_session_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"sessions"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /supervisory_teams/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_supervisory_team_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"supervisory_teams"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /tracking/billing_support_download_files/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_tracking_billing_support_download_file_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"tracking"],[2,[7,"/"],[2,[6,"billing_support_download_files"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /tracking/billing_support_questionnaires/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_tracking_billing_support_questionnaire_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"tracking"],[2,[7,"/"],[2,[6,"billing_support_questionnaires"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /tracking/billing_support_upload_files/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_tracking_billing_support_upload_file_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"tracking"],[2,[7,"/"],[2,[6,"billing_support_upload_files"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /tracking/billing_support_upload_files/:billing_support_upload_file_id/billing_support_error_file/new(.:format)
 * @param {any} billing_support_upload_file_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_tracking_billing_support_upload_file_billing_support_error_file_path = __jsr.r({"billing_support_upload_file_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tracking"],[2,[7,"/"],[2,[6,"billing_support_upload_files"],[2,[7,"/"],[2,[3,"billing_support_upload_file_id"],[2,[7,"/"],[2,[6,"billing_support_error_file"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /tracking/enrollment_files/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_tracking_enrollment_file_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"tracking"],[2,[7,"/"],[2,[6,"enrollment_files"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /tracking/enrollment_files/:enrollment_file_id/enrollment_records/new(.:format)
 * @param {any} enrollment_file_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_tracking_enrollment_file_enrollment_record_path = __jsr.r({"enrollment_file_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tracking"],[2,[7,"/"],[2,[6,"enrollment_files"],[2,[7,"/"],[2,[3,"enrollment_file_id"],[2,[7,"/"],[2,[6,"enrollment_records"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_user_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users/unlock/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_user_unlock_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"unlock"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /open_past_due_tasks(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const open_past_due_tasks_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"open_past_due_tasks"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /open_upcoming_tasks(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const open_upcoming_tasks_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"open_upcoming_tasks"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /password(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const password_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"password"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /password_resets/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const password_reset_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"password_resets"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /password_resets/:password_reset_id/update_password(.:format)
 * @param {any} password_reset_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const password_reset_update_password_path = __jsr.r({"password_reset_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"password_resets"],[2,[7,"/"],[2,[3,"password_reset_id"],[2,[7,"/"],[2,[6,"update_password"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /password_resets(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const password_resets_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"password_resets"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /patients/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const patient_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"patients"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /patients/:patient_id/accessible_external_assessments(.:format)
 * @param {any} patient_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const patient_accessible_external_assessments_path = __jsr.r({"patient_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"patients"],[2,[7,"/"],[2,[3,"patient_id"],[2,[7,"/"],[2,[6,"accessible_external_assessments"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /patients/:patient_id/accessible_external_care_plans(.:format)
 * @param {any} patient_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const patient_accessible_external_care_plans_path = __jsr.r({"patient_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"patients"],[2,[7,"/"],[2,[3,"patient_id"],[2,[7,"/"],[2,[6,"accessible_external_care_plans"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /patients/:patient_id/accessible_external_documents(.:format)
 * @param {any} patient_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const patient_accessible_external_documents_path = __jsr.r({"patient_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"patients"],[2,[7,"/"],[2,[3,"patient_id"],[2,[7,"/"],[2,[6,"accessible_external_documents"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /patients/:patient_id/accessible_external_encounters(.:format)
 * @param {any} patient_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const patient_accessible_external_encounters_path = __jsr.r({"patient_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"patients"],[2,[7,"/"],[2,[3,"patient_id"],[2,[7,"/"],[2,[6,"accessible_external_encounters"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /patients/:patient_id/assessment_providers(.:format)
 * @param {any} patient_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const patient_assessment_providers_path = __jsr.r({"patient_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"patients"],[2,[7,"/"],[2,[3,"patient_id"],[2,[7,"/"],[2,[6,"assessment_providers"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /patients/:patient_id/billing_support/:id(.:format)
 * @param {any} patient_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const patient_billing_support_path = __jsr.r({"patient_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"patients"],[2,[7,"/"],[2,[3,"patient_id"],[2,[7,"/"],[2,[6,"billing_support"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /patients/:patient_id/claims(.:format)
 * @param {any} patient_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const patient_claims_path = __jsr.r({"patient_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"patients"],[2,[7,"/"],[2,[3,"patient_id"],[2,[7,"/"],[2,[6,"claims"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /patient_flags/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const patient_flag_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"patient_flags"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /patient_flag_assignments/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const patient_flag_assignment_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"patient_flag_assignments"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /patient_flag_assignment_batches(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const patient_flag_assignment_batches_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"patient_flag_assignment_batches"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /patient_flags(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const patient_flags_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"patient_flags"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /patient_tracking_files/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const patient_tracking_file_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"patient_tracking_files"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /patient_tracking_files(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const patient_tracking_files_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"patient_tracking_files"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /care_plans/:id/patient_version(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const patient_version_care_plan_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"care_plans"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"patient_version"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /patients(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const patients_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"patients"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /payers/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const payer_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"payers"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /payers(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const payers_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"payers"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /pdf_assessments/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const pdf_assessment_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pdf_assessments"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /pghero
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const pg_hero_path = __jsr.r({}, [2,[7,"/"],[6,"pghero"]]);

/**
 * Generates rails route to
 * /pghero(/:database)/space(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const pg_hero_space_path = __jsr.r({"database":{},"format":{}}, [2,[1,[2,[2,[2,[7,"/"],[6,"pghero"]],[7,"/"]],[3,"database"]]],[2,[7,"/"],[2,[6,"space"],[1,[2,[8,"."],[3,"format"]]]]]]);

/**
 * Generates rails route to
 * /pghero(/:database)/space/:relation(.:format)
 * @param {any} relation
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const pg_hero_relation_space_path = __jsr.r({"database":{},"relation":{"r":true},"format":{}}, [2,[1,[2,[2,[2,[7,"/"],[6,"pghero"]],[7,"/"]],[3,"database"]]],[2,[7,"/"],[2,[6,"space"],[2,[7,"/"],[2,[3,"relation"],[1,[2,[8,"."],[3,"format"]]]]]]]]);

/**
 * Generates rails route to
 * /pghero(/:database)/index_bloat(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const pg_hero_index_bloat_path = __jsr.r({"database":{},"format":{}}, [2,[1,[2,[2,[2,[7,"/"],[6,"pghero"]],[7,"/"]],[3,"database"]]],[2,[7,"/"],[2,[6,"index_bloat"],[1,[2,[8,"."],[3,"format"]]]]]]);

/**
 * Generates rails route to
 * /pghero(/:database)/live_queries(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const pg_hero_live_queries_path = __jsr.r({"database":{},"format":{}}, [2,[1,[2,[2,[2,[7,"/"],[6,"pghero"]],[7,"/"]],[3,"database"]]],[2,[7,"/"],[2,[6,"live_queries"],[1,[2,[8,"."],[3,"format"]]]]]]);

/**
 * Generates rails route to
 * /pghero(/:database)/queries(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const pg_hero_queries_path = __jsr.r({"database":{},"format":{}}, [2,[1,[2,[2,[2,[7,"/"],[6,"pghero"]],[7,"/"]],[3,"database"]]],[2,[7,"/"],[2,[6,"queries"],[1,[2,[8,"."],[3,"format"]]]]]]);

/**
 * Generates rails route to
 * /pghero(/:database)/queries/:query_hash(.:format)
 * @param {any} query_hash
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const pg_hero_show_query_path = __jsr.r({"database":{},"query_hash":{"r":true},"format":{}}, [2,[1,[2,[2,[2,[7,"/"],[6,"pghero"]],[7,"/"]],[3,"database"]]],[2,[7,"/"],[2,[6,"queries"],[2,[7,"/"],[2,[3,"query_hash"],[1,[2,[8,"."],[3,"format"]]]]]]]]);

/**
 * Generates rails route to
 * /pghero(/:database)/system(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const pg_hero_system_path = __jsr.r({"database":{},"format":{}}, [2,[1,[2,[2,[2,[7,"/"],[6,"pghero"]],[7,"/"]],[3,"database"]]],[2,[7,"/"],[2,[6,"system"],[1,[2,[8,"."],[3,"format"]]]]]]);

/**
 * Generates rails route to
 * /pghero(/:database)/cpu_usage(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const pg_hero_cpu_usage_path = __jsr.r({"database":{},"format":{}}, [2,[1,[2,[2,[2,[7,"/"],[6,"pghero"]],[7,"/"]],[3,"database"]]],[2,[7,"/"],[2,[6,"cpu_usage"],[1,[2,[8,"."],[3,"format"]]]]]]);

/**
 * Generates rails route to
 * /pghero(/:database)/connection_stats(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const pg_hero_connection_stats_path = __jsr.r({"database":{},"format":{}}, [2,[1,[2,[2,[2,[7,"/"],[6,"pghero"]],[7,"/"]],[3,"database"]]],[2,[7,"/"],[2,[6,"connection_stats"],[1,[2,[8,"."],[3,"format"]]]]]]);

/**
 * Generates rails route to
 * /pghero(/:database)/replication_lag_stats(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const pg_hero_replication_lag_stats_path = __jsr.r({"database":{},"format":{}}, [2,[1,[2,[2,[2,[7,"/"],[6,"pghero"]],[7,"/"]],[3,"database"]]],[2,[7,"/"],[2,[6,"replication_lag_stats"],[1,[2,[8,"."],[3,"format"]]]]]]);

/**
 * Generates rails route to
 * /pghero(/:database)/load_stats(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const pg_hero_load_stats_path = __jsr.r({"database":{},"format":{}}, [2,[1,[2,[2,[2,[7,"/"],[6,"pghero"]],[7,"/"]],[3,"database"]]],[2,[7,"/"],[2,[6,"load_stats"],[1,[2,[8,"."],[3,"format"]]]]]]);

/**
 * Generates rails route to
 * /pghero(/:database)/free_space_stats(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const pg_hero_free_space_stats_path = __jsr.r({"database":{},"format":{}}, [2,[1,[2,[2,[2,[7,"/"],[6,"pghero"]],[7,"/"]],[3,"database"]]],[2,[7,"/"],[2,[6,"free_space_stats"],[1,[2,[8,"."],[3,"format"]]]]]]);

/**
 * Generates rails route to
 * /pghero(/:database)/explain(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const pg_hero_explain_path = __jsr.r({"database":{},"format":{}}, [2,[1,[2,[2,[2,[7,"/"],[6,"pghero"]],[7,"/"]],[3,"database"]]],[2,[7,"/"],[2,[6,"explain"],[1,[2,[8,"."],[3,"format"]]]]]]);

/**
 * Generates rails route to
 * /pghero(/:database)/tune(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const pg_hero_tune_path = __jsr.r({"database":{},"format":{}}, [2,[1,[2,[2,[2,[7,"/"],[6,"pghero"]],[7,"/"]],[3,"database"]]],[2,[7,"/"],[2,[6,"tune"],[1,[2,[8,"."],[3,"format"]]]]]]);

/**
 * Generates rails route to
 * /pghero(/:database)/connections(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const pg_hero_connections_path = __jsr.r({"database":{},"format":{}}, [2,[1,[2,[2,[2,[7,"/"],[6,"pghero"]],[7,"/"]],[3,"database"]]],[2,[7,"/"],[2,[6,"connections"],[1,[2,[8,"."],[3,"format"]]]]]]);

/**
 * Generates rails route to
 * /pghero(/:database)/maintenance(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const pg_hero_maintenance_path = __jsr.r({"database":{},"format":{}}, [2,[1,[2,[2,[2,[7,"/"],[6,"pghero"]],[7,"/"]],[3,"database"]]],[2,[7,"/"],[2,[6,"maintenance"],[1,[2,[8,"."],[3,"format"]]]]]]);

/**
 * Generates rails route to
 * /pghero(/:database)/kill(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const pg_hero_kill_path = __jsr.r({"database":{},"format":{}}, [2,[1,[2,[2,[2,[7,"/"],[6,"pghero"]],[7,"/"]],[3,"database"]]],[2,[7,"/"],[2,[6,"kill"],[1,[2,[8,"."],[3,"format"]]]]]]);

/**
 * Generates rails route to
 * /pghero(/:database)/kill_long_running_queries(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const pg_hero_kill_long_running_queries_path = __jsr.r({"database":{},"format":{}}, [2,[1,[2,[2,[2,[7,"/"],[6,"pghero"]],[7,"/"]],[3,"database"]]],[2,[7,"/"],[2,[6,"kill_long_running_queries"],[1,[2,[8,"."],[3,"format"]]]]]]);

/**
 * Generates rails route to
 * /pghero(/:database)/kill_all(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const pg_hero_kill_all_path = __jsr.r({"database":{},"format":{}}, [2,[1,[2,[2,[2,[7,"/"],[6,"pghero"]],[7,"/"]],[3,"database"]]],[2,[7,"/"],[2,[6,"kill_all"],[1,[2,[8,"."],[3,"format"]]]]]]);

/**
 * Generates rails route to
 * /pghero(/:database)/enable_query_stats(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const pg_hero_enable_query_stats_path = __jsr.r({"database":{},"format":{}}, [2,[1,[2,[2,[2,[7,"/"],[6,"pghero"]],[7,"/"]],[3,"database"]]],[2,[7,"/"],[2,[6,"enable_query_stats"],[1,[2,[8,"."],[3,"format"]]]]]]);

/**
 * Generates rails route to
 * /pghero(/:database)/reset_query_stats(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const pg_hero_reset_query_stats_path = __jsr.r({"database":{},"format":{}}, [2,[1,[2,[2,[2,[7,"/"],[6,"pghero"]],[7,"/"]],[3,"database"]]],[2,[7,"/"],[2,[6,"reset_query_stats"],[1,[2,[8,"."],[3,"format"]]]]]]);

/**
 * Generates rails route to
 * /pghero(/:database)/system_stats(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const pg_hero_system_stats_path = __jsr.r({"database":{},"format":{}}, [2,[1,[2,[2,[2,[7,"/"],[6,"pghero"]],[7,"/"]],[3,"database"]]],[2,[7,"/"],[2,[6,"system_stats"],[1,[2,[8,"."],[3,"format"]]]]]]);

/**
 * Generates rails route to
 * /pghero(/:database)/query_stats(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const pg_hero_query_stats_path = __jsr.r({"database":{},"format":{}}, [2,[1,[2,[2,[2,[7,"/"],[6,"pghero"]],[7,"/"]],[3,"database"]]],[2,[7,"/"],[2,[6,"query_stats"],[1,[2,[8,"."],[3,"format"]]]]]]);

/**
 * Generates rails route to
 * /pghero/(:database)(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const pg_hero_root_path = __jsr.r({"database":{},"format":{}}, [2,[2,[2,[7,"/"],[6,"pghero"]],[7,"/"]],[2,[1,[3,"database"]],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /custom_assessment_schema/versions/:id/preview(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const preview_custom_assessment_schema_version_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"custom_assessment_schema"],[2,[7,"/"],[2,[6,"versions"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"preview"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /providers/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const provider_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"providers"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /providers(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const providers_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"providers"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /otp/qr_code(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const qr_code_otp_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"otp"],[2,[7,"/"],[2,[6,"qr_code"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /sessions/recreate(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const recreate_sessions_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"sessions"],[2,[7,"/"],[2,[6,"recreate"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /otp/register(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const register_otp_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"otp"],[2,[7,"/"],[2,[6,"register"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /reports/caseload_coverage(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const reports_caseload_coverage_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"reports"],[2,[7,"/"],[2,[6,"caseload_coverage"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /reports/caseload_overview/adult/assessments(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const reports_caseload_overview_adult_assessments_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"reports"],[2,[7,"/"],[2,[6,"caseload_overview"],[2,[7,"/"],[2,[6,"adult"],[2,[7,"/"],[2,[6,"assessments"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /reports/caseload_overview/adult/care_plans(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const reports_caseload_overview_adult_care_plans_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"reports"],[2,[7,"/"],[2,[6,"caseload_overview"],[2,[7,"/"],[2,[6,"adult"],[2,[7,"/"],[2,[6,"care_plans"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /reports/caseload_overview/adult/ces_tools(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const reports_caseload_overview_adult_ces_tools_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"reports"],[2,[7,"/"],[2,[6,"caseload_overview"],[2,[7,"/"],[2,[6,"adult"],[2,[7,"/"],[2,[6,"ces_tools"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /reports/caseload_overview/child/assessments(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const reports_caseload_overview_child_assessments_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"reports"],[2,[7,"/"],[2,[6,"caseload_overview"],[2,[7,"/"],[2,[6,"child"],[2,[7,"/"],[2,[6,"assessments"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /reports/caseload_overview/child/care_plans(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const reports_caseload_overview_child_care_plans_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"reports"],[2,[7,"/"],[2,[6,"caseload_overview"],[2,[7,"/"],[2,[6,"child"],[2,[7,"/"],[2,[6,"care_plans"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /reports/caseload_overview/child/childrens_hcbs_workflows(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const reports_caseload_overview_child_childrens_hcbs_workflows_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"reports"],[2,[7,"/"],[2,[6,"caseload_overview"],[2,[7,"/"],[2,[6,"child"],[2,[7,"/"],[2,[6,"childrens_hcbs_workflows"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /reports/end_date_reason_code_summary(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const reports_end_date_reason_code_summary_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"reports"],[2,[7,"/"],[2,[6,"end_date_reason_code_summary"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /reports/hcbs_status(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const reports_hcbs_status_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"reports"],[2,[7,"/"],[2,[6,"hcbs_status"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /reports(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const reports_root_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"reports"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /reports/segment_lengths(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const reports_segment_lengths_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"reports"],[2,[7,"/"],[2,[6,"segment_lengths"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /reports/segment_summary_line_chart(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const reports_segment_summary_line_chart_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"reports"],[2,[7,"/"],[2,[6,"segment_summary_line_chart"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /reports/tracking/reconciliation(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const reports_tracking_reconciliation_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"reports"],[2,[7,"/"],[2,[6,"tracking"],[2,[7,"/"],[2,[6,"reconciliation"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /reports/unlocked_encounters/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const reports_unlocked_encounter_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"reports"],[2,[7,"/"],[2,[6,"unlocked_encounters"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /reports/unlocked_encounters(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const reports_unlocked_encounters_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"reports"],[2,[7,"/"],[2,[6,"unlocked_encounters"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const root_path = __jsr.r({}, [7,"/"]);

/**
 * Generates rails route to
 * /providers/search(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const search_providers_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"providers"],[2,[7,"/"],[2,[6,"search"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /segments/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const segment_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"segments"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /segments/:segment_id/pend(.:format)
 * @param {any} segment_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const segment_pend_path = __jsr.r({"segment_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"segments"],[2,[7,"/"],[2,[3,"segment_id"],[2,[7,"/"],[2,[6,"pend"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /sessions/session_test(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const session_test_sessions_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"sessions"],[2,[7,"/"],[2,[6,"session_test"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /sessions(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const sessions_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"sessions"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /signed_care_plans/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const signed_care_plan_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"signed_care_plans"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /otp/sms(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const sms_otp_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"otp"],[2,[7,"/"],[2,[6,"sms"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /supervisory_teams/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const supervisory_team_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"supervisory_teams"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /supervisory_teams(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const supervisory_teams_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"supervisory_teams"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /supervisory_teams_dashboard(/:team_id)(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const supervisory_teams_dashboard_path = __jsr.r({"team_id":{},"format":{}}, [2,[7,"/"],[2,[6,"supervisory_teams_dashboard"],[2,[1,[2,[7,"/"],[3,"team_id"]]],[1,[2,[8,"."],[3,"format"]]]]]]);

/**
 * Generates rails route to
 * /tracking/billing_support_download_files/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const tracking_billing_support_download_file_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tracking"],[2,[7,"/"],[2,[6,"billing_support_download_files"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /tracking/billing_support_download_files/:billing_support_download_file_id/errors(.:format)
 * @param {any} billing_support_download_file_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const tracking_billing_support_download_file_errors_path = __jsr.r({"billing_support_download_file_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tracking"],[2,[7,"/"],[2,[6,"billing_support_download_files"],[2,[7,"/"],[2,[3,"billing_support_download_file_id"],[2,[7,"/"],[2,[6,"errors"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /tracking/billing_support_download_files/:billing_support_download_file_id/overlaps(.:format)
 * @param {any} billing_support_download_file_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const tracking_billing_support_download_file_overlaps_path = __jsr.r({"billing_support_download_file_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tracking"],[2,[7,"/"],[2,[6,"billing_support_download_files"],[2,[7,"/"],[2,[3,"billing_support_download_file_id"],[2,[7,"/"],[2,[6,"overlaps"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /tracking/billing_support_download_files/:billing_support_download_file_id/records(.:format)
 * @param {any} billing_support_download_file_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const tracking_billing_support_download_file_records_path = __jsr.r({"billing_support_download_file_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tracking"],[2,[7,"/"],[2,[6,"billing_support_download_files"],[2,[7,"/"],[2,[3,"billing_support_download_file_id"],[2,[7,"/"],[2,[6,"records"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /tracking/billing_support_download_files(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const tracking_billing_support_download_files_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"tracking"],[2,[7,"/"],[2,[6,"billing_support_download_files"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /tracking/billing_support_questionnaires/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const tracking_billing_support_questionnaire_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tracking"],[2,[7,"/"],[2,[6,"billing_support_questionnaires"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /tracking/billing_support_questionnaires(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const tracking_billing_support_questionnaires_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"tracking"],[2,[7,"/"],[2,[6,"billing_support_questionnaires"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /tracking/billing_support_upload_files/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const tracking_billing_support_upload_file_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tracking"],[2,[7,"/"],[2,[6,"billing_support_upload_files"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /tracking/billing_support_upload_files/:billing_support_upload_file_id/billing_support_error_file/:id(.:format)
 * @param {any} billing_support_upload_file_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const tracking_billing_support_upload_file_billing_support_error_file_path = __jsr.r({"billing_support_upload_file_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tracking"],[2,[7,"/"],[2,[6,"billing_support_upload_files"],[2,[7,"/"],[2,[3,"billing_support_upload_file_id"],[2,[7,"/"],[2,[6,"billing_support_error_file"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /tracking/billing_support_upload_files/:billing_support_upload_file_id/billing_support_error_file(.:format)
 * @param {any} billing_support_upload_file_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const tracking_billing_support_upload_file_billing_support_error_file_index_path = __jsr.r({"billing_support_upload_file_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tracking"],[2,[7,"/"],[2,[6,"billing_support_upload_files"],[2,[7,"/"],[2,[3,"billing_support_upload_file_id"],[2,[7,"/"],[2,[6,"billing_support_error_file"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /tracking/billing_support_upload_files(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const tracking_billing_support_upload_files_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"tracking"],[2,[7,"/"],[2,[6,"billing_support_upload_files"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /tracking/delete_records/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const tracking_delete_record_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tracking"],[2,[7,"/"],[2,[6,"delete_records"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /tracking/enrollment_files/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const tracking_enrollment_file_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tracking"],[2,[7,"/"],[2,[6,"enrollment_files"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /tracking/enrollment_files/:enrollment_file_id/enrollment_records/:id(.:format)
 * @param {any} enrollment_file_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const tracking_enrollment_file_enrollment_record_path = __jsr.r({"enrollment_file_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tracking"],[2,[7,"/"],[2,[6,"enrollment_files"],[2,[7,"/"],[2,[3,"enrollment_file_id"],[2,[7,"/"],[2,[6,"enrollment_records"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /tracking/enrollment_files/:enrollment_file_id/enrollment_records(.:format)
 * @param {any} enrollment_file_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const tracking_enrollment_file_enrollment_records_path = __jsr.r({"enrollment_file_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tracking"],[2,[7,"/"],[2,[6,"enrollment_files"],[2,[7,"/"],[2,[3,"enrollment_file_id"],[2,[7,"/"],[2,[6,"enrollment_records"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /tracking/enrollment_files(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const tracking_enrollment_files_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"tracking"],[2,[7,"/"],[2,[6,"enrollment_files"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /tracking/segment_records/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const tracking_segment_record_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tracking"],[2,[7,"/"],[2,[6,"segment_records"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /transferred_patients(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const transferred_patients_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"transferred_patients"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /encounters/:id/unlock(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const unlock_encounter_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"encounters"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"unlock"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /assessment_providers/:id/update_dental(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_dental_assessment_provider_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"assessment_providers"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"update_dental"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /assessment_providers/:id/update_mental_health(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_mental_health_assessment_provider_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"assessment_providers"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"update_mental_health"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /assessment_providers/:id/update_other(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_other_assessment_provider_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"assessment_providers"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"update_other"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /assessment_providers/:id/update_physical_rehab(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_physical_rehab_assessment_provider_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"assessment_providers"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"update_physical_rehab"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /assessment_providers/:id/update_podiatry(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_podiatry_assessment_provider_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"assessment_providers"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"update_podiatry"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /assessment_providers/:id/update_prenatal(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_prenatal_assessment_provider_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"assessment_providers"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"update_prenatal"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /assessment_providers/:id/update_primary_medical(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_primary_medical_assessment_provider_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"assessment_providers"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"update_primary_medical"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /assessment_providers/:id/update_substance_use(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_substance_use_assessment_provider_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"assessment_providers"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"update_substance_use"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /assessment_providers/:id/update_vision(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_vision_assessment_provider_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"assessment_providers"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"update_vision"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /uploads/accessible_external_documents/:id/:filename
 * @param {any} id
 * @param {any} filename
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const uploads_accessible_external_document_path = __jsr.r({"id":{"r":true},"filename":{"r":true}}, [2,[7,"/"],[2,[6,"uploads"],[2,[7,"/"],[2,[6,"accessible_external_documents"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[3,"filename"]]]]]]]]);

/**
 * Generates rails route to
 * /uploads/accessible_external_health_home_consents/:id/:filename
 * @param {any} id
 * @param {any} filename
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const uploads_accessible_external_health_home_consent_path = __jsr.r({"id":{"r":true},"filename":{"r":true}}, [2,[7,"/"],[2,[6,"uploads"],[2,[7,"/"],[2,[6,"accessible_external_health_home_consents"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[3,"filename"]]]]]]]]);

/**
 * Generates rails route to
 * /uploads/accessible_external_health_home_opt_outs/:id/:filename
 * @param {any} id
 * @param {any} filename
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const uploads_accessible_external_health_home_opt_out_path = __jsr.r({"id":{"r":true},"filename":{"r":true}}, [2,[7,"/"],[2,[6,"uploads"],[2,[7,"/"],[2,[6,"accessible_external_health_home_opt_outs"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[3,"filename"]]]]]]]]);

/**
 * Generates rails route to
 * /uploads/accessible_external_pdf_assessments/:id/:filename
 * @param {any} id
 * @param {any} filename
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const uploads_accessible_external_pdf_assessment_path = __jsr.r({"id":{"r":true},"filename":{"r":true}}, [2,[7,"/"],[2,[6,"uploads"],[2,[7,"/"],[2,[6,"accessible_external_pdf_assessments"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[3,"filename"]]]]]]]]);

/**
 * Generates rails route to
 * /uploads/documents/:id/:filename
 * @param {any} id
 * @param {any} filename
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const uploads_document_path = __jsr.r({"id":{"r":true},"filename":{"r":true}}, [2,[7,"/"],[2,[6,"uploads"],[2,[7,"/"],[2,[6,"documents"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[3,"filename"]]]]]]]]);

/**
 * Generates rails route to
 * /uploads/health_home_consents/:id/:filename
 * @param {any} id
 * @param {any} filename
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const uploads_health_home_consent_path = __jsr.r({"id":{"r":true},"filename":{"r":true}}, [2,[7,"/"],[2,[6,"uploads"],[2,[7,"/"],[2,[6,"health_home_consents"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[3,"filename"]]]]]]]]);

/**
 * Generates rails route to
 * /uploads/health_home_opt_outs/:id/:filename
 * @param {any} id
 * @param {any} filename
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const uploads_health_home_opt_out_path = __jsr.r({"id":{"r":true},"filename":{"r":true}}, [2,[7,"/"],[2,[6,"uploads"],[2,[7,"/"],[2,[6,"health_home_opt_outs"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[3,"filename"]]]]]]]]);

/**
 * Generates rails route to
 * /uploads/signed_care_plans/:id/:filename
 * @param {any} id
 * @param {any} filename
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const uploads_patient_signed_care_plan_path = __jsr.r({"id":{"r":true},"filename":{"r":true}}, [2,[7,"/"],[2,[6,"uploads"],[2,[7,"/"],[2,[6,"signed_care_plans"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[3,"filename"]]]]]]]]);

/**
 * Generates rails route to
 * /uploads/pdf_assessments/:id/:filename
 * @param {any} id
 * @param {any} filename
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const uploads_pdf_assessment_path = __jsr.r({"id":{"r":true},"filename":{"r":true}}, [2,[7,"/"],[2,[6,"uploads"],[2,[7,"/"],[2,[6,"pdf_assessments"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[3,"filename"]]]]]]]]);

/**
 * Generates rails route to
 * /uploads/signed_harp_care_plans/:id/:filename
 * @param {any} id
 * @param {any} filename
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const uploads_signed_harp_care_plan_path = __jsr.r({"id":{"r":true},"filename":{"r":true}}, [2,[7,"/"],[2,[6,"uploads"],[2,[7,"/"],[2,[6,"signed_harp_care_plans"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[3,"filename"]]]]]]]]);

/**
 * Generates rails route to
 * /users/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users/:user_id/activity_feed(.:format)
 * @param {any} user_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_activity_feed_index_path = __jsr.r({"user_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"user_id"],[2,[7,"/"],[2,[6,"activity_feed"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/unlock(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_unlock_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"unlock"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /otp/verify(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const verify_otp_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"otp"],[2,[7,"/"],[2,[6,"verify"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /tracking/billing_support_questionnaires/:id/void(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const void_tracking_billing_support_questionnaire_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tracking"],[2,[7,"/"],[2,[6,"billing_support_questionnaires"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"void"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

